import {useState} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {isEmpty} from 'lodash';
import aggFuncs from './aggFuncs';
import {colDef} from './columnConfig';
import {ROW_HEIGHT} from '../../../../components/core/Grid/config/defaultConfig';
import Grid from '../../../../components/core/Grid';
import { getExcelExportConfigs} from '../../../../modules/Grid/helper';
import {
  getGridData, gridHeader, filterVisibility, currentViewSelector,
  columnsMetadata, preDefinedSortingState, noRowDataSelector, selectedGroup,
  viewName, getFilters, selectCollapseState, columnSortingStateMappedData,
  groupByFieldSelector, startExcelExport, getFilterModel, filterByMappedData,
  getLastEvent, allViewsSelector, columnsMetadataSelector, currentReportIdSelector,
  isReportGridDataLoadingSelector
} from '../../../../selectors/pages/reports/individualReport';
import mapDispatchToProps from './actionsHandler';
import withGrid from '../../../../components/hoc/withGrid';
import {userSelector} from '../../../../selectors/user';
import {reportDisclaimerSelector, labelSelector, reportsSelectedFirms} from '../../../../selectors/app';
import {
  getNewOrderedColumns, getNewVisibleColumns,
  getNewWidthedColumns
} from '../../../../helpers/gridFund';
import {getUpdatedColumns, onEditView} from '../../utils';
import {accountIdentifierPreference, fundIdentifierPreference} from '../../../../selectors/preferences';
import {WS_DEFAULT_GROUPBY, GROUP_TYPE} from '../../../../constants/preferences';

const isWireSettlementView = (currentReportId) => (
  currentReportId === 'wire-settlement'
);

const isDCSSView = (currentReportId) => (
  currentReportId === 'debit-credit-settlement'
);

const wsRowGroup = [
  GROUP_TYPE.SETTLEMENT_GROUP,
  GROUP_TYPE.SETTLEMENT_LOCATION,
];

const checkRowGroup = (data, currentReportId) => {
  if (isWireSettlementView(currentReportId)) {
    return data.some((item) => wsRowGroup.includes(item.colId));
  }
  return true;
};

const IndividualReportGrid = props => {
  const {currentView, allViews, addEditDeleteViewData, currentReportId, groupByField} = props;
  let {columnDefs} = props;
  const config = {
    aggFuncs,
    enableHandleGrouping: true,
    groupDefaultExpanded: props.collapseState === true ? 0 : -1,
    enableServerSideSorting: true,
    suppressScrollOnNewData: true,
    groupSuppressAutoColumn: true,
    context: {  // custom prop
      suppressGroupCheckbox: true
    },
    enableServerSideFilter: false,
    sortingOrder: ['desc', 'asc'],
    noRowsOverlayComponentParams: {
      noRowMsg: props.isInvalidFilter ? props.labels.tkCopyPort17 : props.noRowDataSelector
    },
    floatingFiltersHeight: 0,
    ...props.gridConfig
  };

  const isWsDefaultGroupBy = (currentReportId) => (
    isWireSettlementView(currentReportId) && groupByField === WS_DEFAULT_GROUPBY
  );

  if (isWireSettlementView(currentReportId) || isDCSSView(currentReportId)) {
    config.getRowHeight = (params) => {
      const {node, columnApi} = params;
      let rowHeight = Number(ROW_HEIGHT);
      const leafNodeRows = node.allLeafChildren;
      const data = !isEmpty(leafNodeRows) ? leafNodeRows[0].data : null;
      if (node && data && node.leafGroup && checkRowGroup(columnApi.getRowGroupColumns(), currentReportId)) {
        const {wireInstruction = '', dcssInstruction = ''} = data;
        const instruction = isWireSettlementView(currentReportId) ? wireInstruction : dcssInstruction;
        const splits = instruction.trim().split('<br/>').filter(r => r);
        if (splits.length > 3) {
          rowHeight *= splits.length / 1.5;
        } else {
          rowHeight += 8;
        }
      }
      return rowHeight;
    };
    config.size = 'sm';

    if (isWsDefaultGroupBy) {
      columnDefs = columnDefs.map((colDef) => {
        const {groupColSpan} = colDef;
        if (groupColSpan) {
          colDef.colSpan = (params) => {
            const {node} = params;
            if (node && node.leafGroup) {
              return groupColSpan;
            }
          };
        }
        return colDef;
      });
    }
  }

  const excelExportConfig = () => {
    const {
      columnDefs,
      currentView,
      disclaimer,
      userInformation,
      selectedFirms,
      labels,
      filterModel
    } = props;

    currentView.label = currentView.name;

    return getExcelExportConfigs({
      columnDefs,
      currentView,
      disclaimer,
      userInformation,
      selectedFirms,
      labels,
      filterModel
    });
  };

  const [startTime, setStartTime] = useState(null);
  const onDispatchLoadStart = () => setStartTime(new Date().getTime());
  const onDispatchLoadEnd = () => {
    const {lastEvent, data, columnDefs, currentView, filterBy} = props;
    lastEvent && props.dispatchGridLoadEnd(
      lastEvent, data.length, columnDefs,
      currentView.name, filterBy, startTime
    );
  };

  const dispatchColumnWidthChanged = (columns) => {
    const updatedColumns = getUpdatedColumns(columns);
    const updatedView = getNewWidthedColumns(updatedColumns, currentView);
    onEditView(updatedView, allViews, addEditDeleteViewData);
  };

  const dispatchColumnMoved = (columns) => {
    const activeView = getNewOrderedColumns(columns, currentView, true);
    onEditView(activeView, allViews, addEditDeleteViewData);
  };

  const dispatchColumnRemove = (colId) => {
    const [updatedColId] = colId.split('_');
    const updatedView = getNewVisibleColumns(updatedColId, currentView);
    onEditView(updatedView, allViews, addEditDeleteViewData);
  };

  const updatedProps = {
    colDef,
    config,
    excelExportConfig: props.excelExportConfig || excelExportConfig,
    dispatchLoadEnd: onDispatchLoadEnd,
    dispatchLoadStart: onDispatchLoadStart,
    dispatchColumnWidthChanged,
    dispatchColumnMoved,
    dispatchColumnRemove,
    ...props,
    columnDefs,
    clientGroupBy: true
  };

  return withGrid(Grid)(updatedProps);
};

const mapStateToProps = (state) => ({
  columnDefs: gridHeader(state),
  data: getGridData(state),
  groupByField: groupByFieldSelector(state),
  viewName: viewName(state),
  lastEvent: getLastEvent(state),
  collapseState: selectCollapseState(state),
  filterVisibility: filterVisibility(state),
  filterBy: getFilters(state),
  userInformation: userSelector(state),
  selectedGroup: selectedGroup(state),
  labels: labelSelector(state),
  currentView: currentViewSelector(state),
  currentReportId: currentReportIdSelector(state),
  allViews: allViewsSelector(state),
  noRowDataSelector: noRowDataSelector(state),
  loading: isReportGridDataLoadingSelector(state),
  filterModel: getFilterModel(state),
  columnsMetadata: columnsMetadata(state),
  columnsMetadataWithKey: columnsMetadataSelector(state),
  preDefinedSortingState: preDefinedSortingState(state),
  startExcelExport: startExcelExport(state),
  filterByMappedData: filterByMappedData(state),
  columnSortingStateMappedData: columnSortingStateMappedData(state),
  disclaimer: reportDisclaimerSelector(state),
  selectedFirms: reportsSelectedFirms(state),
  enableAddTradeToBlotter: true,
  preferredAccountIdentifier: accountIdentifierPreference(state),
  preferredFundIdentifier: fundIdentifierPreference(state)
});

IndividualReportGrid.propTypes = {
  filterBy: PropTypes.array,
  filterModel: PropTypes.object,
  labels: PropTypes.object,
  loading: PropTypes.bool,
  columnsMetadata: PropTypes.array,
  startExcelExport: PropTypes.object,
  groupByField: PropTypes.string,
  filterVisibility: PropTypes.string,
  disclaimer: PropTypes.array,
  selectedFirms: PropTypes.array,
  filterByMappedData: PropTypes.array,
  columnSortingStateMappedData: PropTypes.array,
  userInformation: PropTypes.object,
  filteredDataLength: PropTypes.number,
  allViews: PropTypes.array,
  addEditDeleteViewData: PropTypes.func,
  currentReportId: PropTypes.string
};

IndividualReportGrid.defaultProps = {
  enableModuleCleanUpOnDestroy: true,
  enableFloatingfilter: true,
  groupByField: ''
};

export default connect(mapStateToProps, mapDispatchToProps)(IndividualReportGrid);
