import React from "react";
import PropTypes from "prop-types";
import {Conditional} from "../../../../../core/Conditional";
import {
  REBATE_NO_ERROR,
  REBATE_NOT_A_NUMBER,
  REBATE_OUT_OF_RANGE,
  REBATE_OUT_OF_RANGE_AND_EXCEEDS_PRECISION
} from "../../constants";
import "./index.scss"

const ErrorMessage = ({labels, errorState}) => {

  const {tkRebateAmountRangeError, tkRebateAmountDecimalPointsError } = labels;

  const renderErrorMessages = () => {
    if (errorState === REBATE_NOT_A_NUMBER || errorState === REBATE_OUT_OF_RANGE) {
      return <span>{tkRebateAmountRangeError}</span>;
    } else if (errorState === REBATE_OUT_OF_RANGE_AND_EXCEEDS_PRECISION) {
      return (
        <ul>
          <li><span>{tkRebateAmountRangeError}</span></li>
          <li><span>{tkRebateAmountDecimalPointsError}</span></li>
        </ul>
      );
    }
    return <span>{tkRebateAmountDecimalPointsError}</span>;
  };

  return (
    <Conditional condition={errorState !== REBATE_NO_ERROR}>
      <div className='fund-rebate-error-message'>
        {renderErrorMessages()}
      </div>
    </Conditional>
  );
};

ErrorMessage.propTypes = {
  labels: PropTypes.object,
  errorState: PropTypes.number,
};

export default ErrorMessage;
