import { getDomainMapper } from "../../../utils/commonUtils";
const domainMapper = getDomainMapper();

export default {
  FETCH_USER_SEARCH_GRID_COLUMN_CONFIG: {
    path: `/content/${domainMapper}/nodes/usersearch-columns-configurations.json`,
    method: 'GET'
  },
  FETCH_USER_SEARCH_VIEWS: {
    path: '/api/v1/users/views/SELFSERVICEADMINUSERSEARCH',
    method: 'GET'
  },
  FETCH_USER_SEARCH: {
    path: '/api/v1/selfserviceadmin/users/autocomplete',
    method: 'POST',
  },
  FETCH_USER_SEARCH_DETAIL: {
    path: '/api/v1/selfserviceadmin/users/detail',
    method: 'GET'
  },
  FETCH_USER_SEARCH_DETAILS: {
    path: '/api/v1/selfserviceadmin/users/search',
    method: 'POST',
  },
  FETCH_USER_ACCOUNT_STATUS: {
    path: '/api/v1/selfserviceadmin/users/details/status',
    method: 'POST',
  },
  EDIT_SAVE_USER_SEARCH_DETAILS: {
    path: '/api/v1/selfserviceadmin/users/details/saveDetails',
    method: 'POST',
  },
  FETCH_ALL_AVAILABLE_ACCOUNTS: {
    path: '/api/v1/selfserviceadmin/users/allAvailableAccounts',
    method: 'POST',
  },
  FETCH_ORGANIZATION_ACCOUNTS: {
    path: '/api/v1/selfserviceadmin/organization/users/accounts',
    method: 'POST',
  },
  FETCH_ORGANIZATION_DETAILS: {
    path: '/api/v1/selfserviceadmin/organization/detail',
    method: 'GET',
  },
  EDIT_SAVE_ORG_DETAILS: {
    path: '/api/v1/selfserviceadmin/organization/id',
    method: 'POST',
  },
  FETCH_ALL_DASHBOARD_MODULES: {
    path: '/api/v1/selfserviceadmin/dashboard/allmodules',
    method: 'GET'
  },
  FETCH_SPECIFIC_DASHBOARD_MODULE: {
    path: '/api/v1/selfserviceadmin/dashboard',
    method: 'GET'
  },
  FETCH_FIRM_ORGANIZATION_MAPPING: {
    path: '/api/v1/selfserviceadmin/organization/map/firm',
    method: 'POST',
  },
  FETCH_RECENT_ORG_SEARCH: {
    path: '/api/v1/recentsearchcategory',
    method: 'GET'
  },
  SAVE_RECENT_ORG_SEARCH: {
    path: '/api/v1/recentsearchcategory',
    method: 'POST'
  },
  FETCH_UNMAPPED_FIRMS_COUNT: {
    path: '/api/v1/selfserviceadmin/organization/firms/availablecount',
    method: 'GET'
  },
  FETCH_UNMAPPED_FIRMS: {
    path: '/api/v1/selfserviceadmin/dashboard/MaintenanceAlerts?type=UNMAPPED_FIRMS',
    method: 'GET'
  },
  FETCH_MAPPED_FIRMS_ACCOUNTS: {
    path: '/api/v1/selfserviceadmin/organization/firms/accountstree',
    method: 'POST',
  }
};
