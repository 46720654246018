import React, {useEffect, useRef, useState} from 'react';
import {PropTypes} from 'prop-types';
import cn from 'classnames';
import isString from 'lodash/isString';
import { Input } from '@gs-ux-uitoolkit-react/input';
import LeftPanel from '../../core/LeftPanel';
import Checkbox from '../../core/CheckBox';
import Button from '../../core/Button';
import HelpContainer from '../../../containers/HelpContainer';
import {usePasswordVisibilityIcon} from './loginHook';
import {analyticsEventName} from './analytics';
import {Conditional} from '../../core/Conditional';
import {getDomainMapper, getLoginRoute, isMosaic, isUserLandedFromMosaicPage} from '../../../utils/commonUtils';
import Constants from '../../../constants/appConstants';
import LoginConstants from '../../../constants/loginConstants';
import {SessionStorage} from '../../../utils/storage';
import Link from '../../core/Link';
import history from '../../../utils/history';
import ValidationItem from '../ValidationItem';
import MosaicErrorTemplate from './errorContainer/mosaic';
import HuntingtonErrorTemplate from './errorContainer/huntington';
import BmoErrorTemplate from './errorContainer/bmo';
import TruistErrorTemplate from './errorContainer/truist';
import './index.scss';
import '../HelpSection/index.scss';

const Login = (props) => {
  const {userName, helpContents} = props;
  const [username, setUsername] = useState(userName);
  const [password, setPassword] = useState('');
  const [rememberUsername, setRememberUsername] = useState(!!userName);

  const {passwordEnabled, passwordTrailingContent} = usePasswordVisibilityIcon();

  const getErrorTemplate = () => {
    switch (getDomainMapper()) {
      case 'mosaic-ui': {
        return MosaicErrorTemplate;
      }
      case 'huntington-ui': {
        return HuntingtonErrorTemplate;
      }
      case 'bmo-ui': {
        return BmoErrorTemplate;
      }
      case 'truist-ui': {
        return TruistErrorTemplate;
      }
      default:
        return MosaicErrorTemplate;
    }
  };

  const onHandleTmxProfilingEvent = (event) => {
    if (isString(event.data)) {
      const tmxSessionId = (event.data ? event.data.split(':')[1] : null);
      const {canPerformTMXProfiling, onTmxProfilingComplete, isInternalUserMFA} = props;
      if (canPerformTMXProfiling && tmxSessionId) {
        console.log(`TMX Profiling completed, TMX session_id is => ${tmxSessionId}`);
        // Dispatch the event to complete the finish call
        const timeout = isInternalUserMFA ? 1000 : 1;
        setTimeout(() => {
          onTmxProfilingComplete(true);
        }, timeout);
      }
    }
  };

  const useTimeout = (cb, timeout) => {
    const cbRef = useRef();

    useEffect(() => {
      cbRef.current = cb;
    }, [cb]);

    useEffect(() => {
      if (timeout !== null) {
        const id = setTimeout(() => {
          cbRef.current();
        }, timeout);
        return () => clearTimeout(id);
      }
    }, [timeout]);
  };

  useTimeout(() => {
    const {isTmxProfilingComplete, onTmxProfilingComplete} = props;
    // Consider the TMX profiling failed, challenge the user anyway
    if (!isTmxProfilingComplete) {
      onTmxProfilingComplete(true);
    }
  }, 8000);

  useEffect(() => {
    const {canPerformTMXProfiling, mfaConfig, onTmxProfilingComplete} = props;
    if (mfaConfig) {
      const {mfaTmxDomain, mfaTmxOrgId, mfaSessionId} = mfaConfig;
      /* If the risk engine is RSA or RSA_TMX, then safely let the Finish call to happen,
         by passing the RSA Device Prints, in this case no need to wait for the TMX Profiling to complete */
      if (canPerformTMXProfiling) {
        try {
          gspr1234.profile(mfaTmxDomain, mfaTmxOrgId, mfaSessionId, '001');
        } catch (err) {
          console.log('Error Occurred during TMX profiling -> ', err);
        }

        // Register the TMX event Listener
        window.addEventListener('message', onHandleTmxProfilingEvent, false);
      } else {
        /* If the risk engine is RSA or RSA_TMX, then safely let the Finish call to happen,
         by passing the RSA Device Prints, in this case no need to wait for the TMX Profiling to complete */
        onTmxProfilingComplete(true);
      }
    }

    return () => {
      // If the risk engine is RSA_TMX or TMX
      if (props.canPerformTMXProfiling) {
        // Un-Register the TMX event Listener
        window.removeEventListener('message', onHandleTmxProfilingEvent, false);
      }
    };
  }, [props.mfaConfig]);

  const submitCredentialsHandler = (e) => {
    e.preventDefault();

    if (username && password) {
      props.submitCredentials({
        username,
        password,
        rememberUsername
      });
    }
  };

  const onInputChange = (event) => {
    const {name, value} = event.target;
    if (name === 'username') {
      setUsername(value.trim());
    } else {
      setPassword(value);
    }
  };

  const onRememberUsername = () => {
    setRememberUsername(!rememberUsername);
  };

  const helpClickHandler = () => {
    props.isShowMosaicHelp(true);
  };

  const onHandleForgotPw = () => {
    history.push(getLoginRoute(Constants.FORGOT_PASSWORD_ROUTE));
    SessionStorage.setItem(LoginConstants.FORGOT_PWD_INIT_TIMESTAMP, new Date().getTime());
  };

  const {
    labels, loginServiceError, nextStep, isShowMosaicHelpFlag,
    isAppLoading, isLoginInProgress, isTmxProfilingComplete, financialConditionsLink = {}
  } = props;

  const leftPanelProps = {
    title: isUserLandedFromMosaicPage() ? labels.tkLogoutHeader1 : labels.tkLeftPanelHeader,
    subTitle: isUserLandedFromMosaicPage() ? labels.tkLogoutMessage : labels.tkLeftPanelSubHeader
  };

  const isSubmitDisable = (!username || !password || isLoginInProgress);
  const ErrorTemplate = getErrorTemplate();

  return (
    <Conditional condition={!isAppLoading}>
        <React.Fragment>
          {
            ((nextStep === 'CHECK_CREDENTIAL') || (nextStep === 'CHANGE_PASSWORD')) && isTmxProfilingComplete &&
              <div className="login-container">
                <div className="login-container__left-panel">
                  <div className="panel-title">
                    <LeftPanel {...leftPanelProps} />
                  </div>
                </div>
                {
                  !isShowMosaicHelpFlag ?
                    <div className="login-container__right-panel">
                      <div className="fields">
                        <div className="fields__header">
                          {labels.tkLoginHeader}
                        </div>
                        <form onSubmit={submitCredentialsHandler}>
                          <div className="fields__inputs">
                            <div className="input-container">
                              <Input
                                name="username"
                                placeholder={labels.tkCredential1}
                                value={username}
                                autoFocus={true}
                                size="md"
                                onChange={onInputChange}
                                className="login-input"
                              />
                            </div>
                            <div className="input-container">
                              <Input
                                name="password"
                                placeholder={labels.tkCredential2}
                                value={password}
                                type={passwordEnabled ? 'password' : 'text'}
                                size="md"
                                onChange={onInputChange}
                                trailingContent={passwordTrailingContent}
                                className={cn({'password-spaced': password}, 'login-input')}
                              />
                            </div>
                            <Conditional condition={loginServiceError}>
                              <ValidationItem
                                key={loginServiceError}
                                customClass="validation"
                                className="check-mark"
                                iconStatus='invalid'
                                lineText={loginServiceError}
                              />
                            </Conditional>
                          </div>
                          <Checkbox
                            extraProps={{'analytics-attribute': analyticsEventName.LOGIN_PAGE_REMEMBER_ME}}
                            key={1}
                            value="true"
                            id="checkbox1"
                            isChecked={rememberUsername}
                            label={labels.tkRememberUsername ? labels.tkRememberUsername : ''}
                            changeHandler={onRememberUsername}
                            isDisabled={false}
                            customLabelClassname="login-checkbox-label" />
                          <div className="login-button">
                            <Button
                              extraProps={{'analytics-attribute': analyticsEventName.LOGIN_PAGE_BUTTON}}
                              type="submit"
                              customClass="button-primary-transparent"
                              label={labels.tkLogIn}
                              isDisabled={isSubmitDisable}
                              clickHandler={submitCredentialsHandler} />
                          </div>
                        </form>
                        <div className='login-sub-button-container'>
                          <Link
                            extraProps={{'analytics-attribute': analyticsEventName.LOGIN_PAGE_FORGET}}
                            customClass="login-sub-button"
                            label={labels.tkForgotPassword}
                            handleClick={onHandleForgotPw}
                          />
                          <span
                            analytics-attribute={analyticsEventName.LOGIN_PAGE_HELP_LINK}
                            className="login-sub-button"
                            onClick={helpClickHandler}>
                            {labels.tkNeedHelp}
                          </span>
                        </div>
                        <Conditional condition={isMosaic()}>
                          <div className="footer">
                            <span className="footer__header">
                              {labels.tkSecurIDFooter}
                            </span>
                            <Conditional condition={Object.keys(financialConditionsLink).length}>
                              <a
                                href={financialConditionsLink.URL}
                                target={financialConditionsLink.newTab ? '_blank' : ''}
                                className="footer__subheader">
                                {labels.tkBrandFooter}
                              </a>
                            </Conditional>
                          </div>
                        </Conditional>
                      </div>
                    </div> :
                    <HelpContainer />
                }
              </div>
          }
          {
            ((nextStep !== 'CHECK_CREDENTIAL') &&  (nextStep !== 'CHANGE_PASSWORD') && (loginServiceError)) &&
              <ErrorTemplate helpContents={helpContents} />
          }
        </React.Fragment>
      </Conditional>
  );
};

Login.propTypes = {
  labels: PropTypes.object,
  financialConditionsLink: PropTypes.object,
  submitCredentials: PropTypes.func,
  nextStep: PropTypes.string,
  userName: PropTypes.string,
  isShowMosaicHelp: PropTypes.func,
  isShowMosaicHelpFlag: PropTypes.bool,
  isAppLoading: PropTypes.bool,
  loginServiceError: PropTypes.string,
  isLoginInProgress: PropTypes.bool,
  helpContents: PropTypes.object,
  onTmxProfilingComplete: PropTypes.object,
  isTmxProfilingComplete: PropTypes.bool,
  canPerformTMXProfiling: PropTypes.bool,
  isInternalUserMFA: PropTypes.bool,
  mfaConfig: PropTypes.object
};

export default Login;
