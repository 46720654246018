import React, {useEffect, useState, useCallback} from 'react';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import translator from '../../../../services/translator';
import Dropdown from '../../../../components/core/Dropdown';
import DownloadButton from '../../../../components/core/DownloadButton/DownloadButton';
import FileDownloadModal from '../FileDownload';
import GearButton from '../../../../components/core/GearButton';
import PopOver from '../../../../components/core/PopOver';
import SwitchButton from '../../../../components/core/SwitchButton';
import {Conditional} from '../../../../components/core/Conditional';
import GridDataFilterButton from '../../../../components/core/FilterButton';
import {isInvalidFilter} from '../../../../utils/validators/validators';
import {getButtons} from '../../../../utils/dateFormatter';
import DatePicker from '../../../../components/core/Date';
import DataFilterButton from '../FilterModal/DataFilterButton';
import SaveCustomReport from '../SaveCustomReport';
import {
  COLUMN_COLID, GROUP_COLUMN_CONFIG, MODEL_ACTION, SORT, PORTFOLIO_FILTER_STATE
} from '../../../../constants/pageConstants';
import {DATE_TIME_FORMATTERS} from '../../../../constants/appConstants';
import {
  getViewChangeAnalyticsData, dispatchAnalytics, getExpandCollapseAnalyticsData,
  getGroupChangeAnalyticsData, getExportViewAnalyticsData
} from '../../analytics';
import {editAppPreferences, toggleCollapsedAllReportState} from '../../../../actions/preferences';
import {
  openCustomViewModal, viewChange, groupChange, toggleFilterVisibility, startExportToExcel,
  addEditDeleteViewData, reportDateRangeChange
} from '../../../../actions/page/reports';
import {
  allViewsSelector, currentViewSelector, dropdownViewNamesSelector,
  getCalendarConfig, getDateRange, getFilters, groupByColumns, groupByFieldSelector,
  selectCollapseState, selectedGroup, startExcelExport, viewName, disableFileDownloadSelector,
  isReportHasVariableColumnsSelector
} from '../../../../selectors/pages/reports/individualReport';
import { openDownloadModal } from '../../../../actions/app/reports';
import {allowPDFDownloadFeatureSelector} from '../../../../selectors/global/index';

const {translate: t} = translator;
const minDate = new Date('2020-01-01');
const maxDate = moment(new Date()).toDate();

const getMaxDate = (viewMaxDate, calendarButtons) => {
  let retDate = maxDate;
  if (! viewMaxDate || !calendarButtons) {
    return retDate;
  }

  const dateRange = calendarButtons.find((button) =>  {
    const {value} = button;
    return (value === viewMaxDate);
  }) || {};
  const { date } = dateRange;
  if (date && date.end) {
    retDate = date.end;
  }
  return retDate;
};

export const ControlPanel = (props) => {
  const {
    dispatchGroupChange, collapseState, groupByField, calendarConfig = {},
    dispatchToggleGroupCollapse, dispatchViewEdit, dispatchOpenFileDownloadModal,
    dispatchDownloadExcel, allowPDFDownload, dateRange, groupByColumns = [],
    views = [], currentView, disableFileDownload, dispatchDateChange, isReportHasVariableColumns
  } = props;

  const [calendarButtons, setCalendarButtons] = useState([]);
  const [filterVisibilityState, setFilterVisibilityState] = useState(false);
  const [isActiveGroupCollapse, setIsActiveGroupCollapse] = useState(collapseState);
  const [isFilterInvalid] = useState(isInvalidFilter);
  const popoverOffset = {x: 0, y: 14};
  const {presets, disableCalendar = false} = calendarConfig;
  const {startDate, endDate, preset, maxDate: viewMaxData} = dateRange;

  useEffect(() => {
    if (presets) {
      const buttons = getButtons();
      const buttonsForView = buttons.filter((button) => (
        presets.indexOf(button.value) !== -1
      ));
      setCalendarButtons(
        (startDate && endDate) ? buttonsForView.map(item => ({...item, isActive: false})) : buttonsForView
      );
    }
  }, [calendarConfig]);


  useEffect(() => {
    const state = (groupByField !== 'none') ? collapseState : false;
    setIsActiveGroupCollapse(state);
  }, [groupByField]);

  const handleGroupChange = (value, selectedItems) => {
    if (isFilterInvalid) {
      return;
    }
    const activeView = cloneDeep(currentView);
    if (value === 'none') {
      activeView.columns = activeView.columns.filter(column => column.colId !== COLUMN_COLID.GROUP);
    } else {
      const index = activeView.columns.findIndex(column => column.colId === COLUMN_COLID.GROUP);
      if (index !== 1) {
        const tradeColumn = activeView.columns.findIndex(column => column.colId === COLUMN_COLID.TRADE);
        activeView.columns.splice(tradeColumn + 1, 0, cloneDeep(GROUP_COLUMN_CONFIG));
      }
    }
    activeView.groupBy = value;
    activeView && activeView.columns.forEach((column, i) => {
      if (column.sort !== SORT.ASC && column.sort !== SORT.DESC) {
        column.sort = 'none';
      }
      column.columnIndex = i;
    });
    dispatchGroupChange({groupBy: value, activeView}, selectedItems.label);
  };

  const handleToggleFilterVisibility = () => {
    const state = !filterVisibilityState;
    const filterVisibility = state ? PORTFOLIO_FILTER_STATE.VISIBLE : PORTFOLIO_FILTER_STATE.HIDDEN;
    props.toggleFilterVisibility({filterVisibility, fetchNewData: !!props.filtersData.length});
    setFilterVisibilityState(state);
  };

  const handleExpandCollapseToggle = (state) => {
    // call dispatch action here to send server call
    dispatchToggleGroupCollapse(state);
    setIsActiveGroupCollapse(state);
  };

  const handleViewEdit = () => {
    dispatchViewEdit(currentView);
  };

  const onDateRangeSelect = useCallback((data) => {
    const formatStr = DATE_TIME_FORMATTERS.YYYY_MM_DD;
    let {start, end } = data;
    start = moment(start).format(formatStr);
    end = moment(end).format(formatStr);
    const { value } = data;
    const date = {
      fromDate: start || '',
      toDate: end || '',
      maxDate: currentView.date && currentView.date.maxDate || maxDate,
      preset: value
    };
    dispatchDateChange(date);
  }, [currentView]);

  const handleOpenFileDownloadModal = (props) => {
    dispatchOpenFileDownloadModal(props);
  };

  const handleDownloadExcel = () => {
    dispatchDownloadExcel(props);
  };

  const handleDownload = () => {
    if (allowPDFDownload) {
      handleOpenFileDownloadModal();
    } else {
      handleDownloadExcel();
    }
  };

  return (
    <Conditional condition={views.length}>
      <div className="report__control-panel">
        <div className="action-container">
          <div className="action">
            <Dropdown
              id="report-groups"
              onSelect={handleGroupChange}
              options={groupByColumns}
              disabled={groupByColumns.length === 1}
              placeholder={t('tkGroupByColonCaps')}
              prepender={t('tkGroupByColonCaps')}
              width={'320px'}
            />
          </div>
          <Conditional condition={!isEmpty(calendarConfig)}>
            <div className="action">
              <DatePicker
                disabled={disableCalendar}
                buttons={calendarButtons}
                endDate={endDate}
                footerCTA={true}
                footerCTAText={t('tkOk')}
                maxDate={getMaxDate(viewMaxData, calendarButtons)}
                minDate={minDate}
                onSelectionComplete={onDateRangeSelect}
                presetRange={preset}
                showMonthDropdown={true}
                showYearDropdown={true}
                startDate={startDate}
                type="dropdown"
                width="auto"
                isReportHasVariableColumns={isReportHasVariableColumns}
              />
            </div>
          </Conditional>
          <div className="action c-align">
            <GridDataFilterButton
              onClick={handleToggleFilterVisibility}
              visibilityState={filterVisibilityState}
            />
          </div>
          <DataFilterButton />
        </div>
        <div className="action-container right-section">
          <div className="action">
            <GearButton onClick={handleViewEdit}/>
          </div>
          <div className="action">
            <DownloadButton
              onClick={handleDownload}
              disabled={disableFileDownload}
            />
            <FileDownloadModal currentView={currentView} />
          </div>
          <div className="action">
            <PopOver overlayOffset={popoverOffset} buttonClass="transparent-button icon-more-menu">
              <div className="dropdown">
                <div className="dropdown-item">
                  <span className="name">{t('tkCollapseAll')}</span>
                  <SwitchButton
                    active={isActiveGroupCollapse}
                    onClick={handleExpandCollapseToggle}
                    disabled={groupByField === 'none'}
                  />
                </div>
              </div>
            </PopOver>
          </div>
          <div className="action">
            <SaveCustomReport />
          </div>
        </div>
      </div>
    </Conditional>
  );
};


ControlPanel.propTypes = {
  calendarConfig: PropTypes.object,
  collapseState: PropTypes.bool,
  currentView: PropTypes.object,
  dateRange: PropTypes.object,
  dispatchGroupChange: PropTypes.func,
  dispatchToggleGroupCollapse: PropTypes.func,
  dispatchViewEdit: PropTypes.func,
  filtersData: PropTypes.array,
  groupByColumns: PropTypes.array,
  groupByField: PropTypes.string,
  toggleFilterVisibility: PropTypes.func,
  views: PropTypes.array,
  dispatchOpenFileDownloadModal: PropTypes.func,
  dispatchDownloadExcel: PropTypes.func,
  allowPDFDownload: PropTypes.bool,
  disableFileDownload: PropTypes.bool,
  dispatchDateChange: PropTypes.func,
  isReportHasVariableColumns: PropTypes.bool
};

export const mapStateToProps = (state) => ({
  calendarConfig: getCalendarConfig(state),
  collapseState: selectCollapseState(state),
  allViews: allViewsSelector(state),
  currentView: currentViewSelector(state),
  isReportHasVariableColumns: isReportHasVariableColumnsSelector(state),
  dateRange: getDateRange(state),
  filtersData: getFilters(state),
  groupByColumns: groupByColumns(state),
  groupByField: groupByFieldSelector(state),
  selectedGroup: selectedGroup(state),
  startExcelExport: startExcelExport(state),
  viewName: viewName(state),
  views: dropdownViewNamesSelector(state),
  allowPDFDownload: allowPDFDownloadFeatureSelector(state),
  disableFileDownload: disableFileDownloadSelector(state)
});

export const mapDispatchToProps = (dispatch) => ({
  dispatchGroupChange: (data, columnName = '') => {
    dispatch(groupChange(data));
    dispatchAnalytics(dispatch, getGroupChangeAnalyticsData(columnName));
  },
  dispatchToggleGroupCollapse: state => {
    dispatch(toggleCollapsedAllReportState(state));
    dispatch(editAppPreferences());
    dispatchAnalytics(dispatch, getExpandCollapseAnalyticsData(state));
  },
  dispatchViewChange: (viewName, data, column) => {
    dispatch(viewChange({id: data.nodeId, report: data.report}));
    dispatchAnalytics(dispatch, getViewChangeAnalyticsData(column, viewName));
  },
  dispatchDateChange: (view) => {
    dispatch(reportDateRangeChange(view));
  },
  dispatchViewAdd: (data) => {
    const params = {'modelActiveMode': MODEL_ACTION.CREATE, 'selectedViewId': data.nodeId};
    dispatch(openCustomViewModal(params));
  },
  dispatchViewEdit: (data) => {
    const params = {'modelActiveMode': MODEL_ACTION.SELECT_COLUMNS, 'selectedViewId': data.id};
    dispatch(openCustomViewModal(params));
  },
  toggleFilterVisibility: (obj) => {
    dispatch(toggleFilterVisibility(obj));
  },
  dispatchOpenFileDownloadModal: (props) => {
    dispatch(openDownloadModal(props));
  },
  dispatchDownloadExcel: (props) => {
    dispatch(startExportToExcel());
    dispatchAnalytics(dispatch, getExportViewAnalyticsData(props.header, props.currentView.name, 'Excel'));
  },
  addEditDeleteViewData: (data) => {
    dispatch(addEditDeleteViewData(data));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ControlPanel);
