const translator = {
  labels : {},
  currency: {}
};

/*
* var regex = /{(.*?)}/g;
myString.replace(regex, (m, c) => ({
    "name": "John",
    "adjective": "simple",
    "type": "string"
})[c.trim().toLowerCase()]);
* */

translator.translate = (key, ...args) => {
  const regex = /@{(.*?)}/g;
  const label = translator.labels[key] || key;
  return args ? label.replace(regex, (m, c) => (Object.assign({}, args))[c.trim().toLowerCase()])  : label;
};

translator.getSymbol = (key) => {
  return translator.currency[key] || '';
};

translator.translateTradeKeys = (key1, key2, ...args) => {
  const regex = /@{(.*?)}/g;
  const label = translator.labels[key1] || key2 && (translator.labels[key2] || key2) || key1;
  return args ? label.replace(regex, (m, c) => (Object.assign({}, args))[c.trim().toLowerCase()])  : label;
};

export default translator;

