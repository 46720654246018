import actionTypes from "../reports/actionTypes";

export const startPollingFileDownload = data => (
  {
    type: actionTypes.START_POLLING_FILE_DOWNLOAD,
    data
  }
);

export const stopPollingFileDownload = () => (
  {
    type: actionTypes.STOP_POLLING_FILE_DOWNLOAD,
    data: {'stopPollingFileDownloadApi': true}
  }
);

export const addFileDownloadPollingData = data => (
  {
    type: actionTypes.ADD_FILE_DOWNLOAD_POLLING_DATA,
    data
  }
);

export const queueFileDownload = data => (
  {
    type: actionTypes.QUEUE_FILE_DOWNLOAD,
    data
  }
);

export const dequeueFileDownload = data => (
  {
    type: actionTypes.DEQUEUE_FILE_DOWNLOAD,
    data
  }
);

export const dequeueAllFileDownload = () => (
  {
    type: actionTypes.DEQUEUE_ALL_FILE_DOWNLOAD
  }
);

export const startFileDownload = data => (
  {
    type: actionTypes.START_FILE_DOWNLOAD,
    data
  }
);

export const openDownloadModal = data => (
  {
    type: actionTypes.OPEN_DOWNLOAD_MODAL,
    data
  }
);

export const closeDownloadModal = data => (
  {
    type: actionTypes.CLOSE_DOWNLOAD_MODAL,
    data
  });

export const addPdfColumnsConfiguration = data => (
  {
    type: actionTypes.ADD_PDF_COLUMNS_CONFIGURATION,
    data
  });

export const reportPdfDownloadStatus = data => ({
  type: actionTypes.REPORT_PDF_DOWNLOAD_STATUS,
    data
});

export const queueExcelDownloadSnackbar = data => ({
  type: actionTypes.QUEUE_EXEL_EXPORT_SNACKBAR,
  data
});

export const dequeueExcelDownloadSnackbar = data => ({
  type: actionTypes.DEQUEUE_EXEL_EXPORT_SNACKBAR,
  data
});

export const setSelectedFilterData = data => (
  {
    type: actionTypes.SET_FILTER_CONFIG_DATA,
    data
  }
);

export const addReportsData = data => ({
  type: actionTypes.ADD_REPORTS_CONFIG_DATA,
  data
});

export const setCustomReportCrudResponse = (data) => ({
  type: actionTypes.SET_CUSTOM_REPORT_CRUD_RESPONSE,
  data: {customReportCrudResponse: data}
});
