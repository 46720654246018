import React, { useMemo } from 'react';
import Exclude from '../../../../../../components/core/Exclude';
import translator from '../../../../../../services/translator';
import { UPDATE_SELECTED_FUNDS, ADD_UPDATE_USER_DETAILS } from '../../../actions';
import useUserMaintenance from '../../../context';

const { translate: t } = translator;

const ControlPanel = () => {
  const {
    state: { userDetails },
    dispatch,
  } = useUserMaintenance();
  const { funds = {} } = userDetails;
  const { fundEntitlementCriteria = [] } = funds;

  const labelConfig = useMemo(() => {
    return {
      currency: { label: t('tkCurrency'), key: 'currencyCode' },
      navType: { label: t('tkNAVType'), key: 'navType' },
      fundFamily: { label: t('tkFundFamily'), key: 'fundFamilyName' },
      domicile: { label: t('tkDomicile'), key: 'domicile' },
      investmentType: { label: t('tkInvestmentType'), key: 'investmentType' },
      shareclassType: { label: t('tkShareClassType'), key: 'shareClassType' },
    };
  });

  const onExclude = (key, value, excluded, label) => {
    const { includedList = [], excludedList = [] } = funds;
    const fundEntitlementCriteria = funds.fundEntitlementCriteria.map(data => {
      if (data.label === label) {
        return {
          label,
          includedList: excluded ? data.includedList.filter(data => data !== value) : [...data.includedList, value],
          excludedList: excluded ? [...data.excludedList, value] : data.excludedList.filter(data => data !== value),
        };
      }
      return data;
    });
    const filtersTerms = fundEntitlementCriteria.map(item => {
      return {
        key: labelConfig[item.label].key,
        list: item.excludedList,
      };
    });
    const payload = [...includedList, ...excludedList]
      .filter(fund => filtersTerms.some(data => data.list.includes(fund[data.key])))
      .map(fund => fund.id);

    dispatch({ type: UPDATE_SELECTED_FUNDS, payload });
    dispatch({
      type: ADD_UPDATE_USER_DETAILS,
      payload: {
        userDetails: {
          ...userDetails,
          funds: {
            ...userDetails.funds,
            fundEntitlementCriteria,
          },
        },
      },
    });
  };

  const getList = (list, excluded) => list.map(item => ({ label: item, excluded }));

  const fundEntitlementFilter = useMemo(() => {
    const index = {
      currency: 0,
      navType: 1,
      fundFamily: 2,
      domicile: 3,
      investmentType: 4,
      shareclassType: 5,
    };
    return fundEntitlementCriteria
      .map(data => {
        const { includedList = [], excludedList = [] } = data;
        return {
          label: data.label,
          index: index[data.label],
          list: [...getList(includedList, false), ...getList(excludedList, true)].sort((a, b) =>
            a.label > b.label ? 1 : b.label > a.label ? -1 : 0
          ),
        };
      })
      .sort((a, b) => a.index - b.index)
      .filter(item => item.list.length);
  }, [fundEntitlementCriteria]);

  return (
    <div className='userdetailsfunds__controlPanel' data-testid='funds-controlePanel'>
      {fundEntitlementFilter.map(({ label, list }) => (
        <div className='userdetailsfunds__controlPanel--list' key={label}>
          <div className='list-title'>{labelConfig[label].label}</div>
          <div className='exclude-list'>
            {list.map(data => (
              <Exclude
                key={data.label}
                selected={!!data.excluded}
                onClick={excluded => {
                  onExclude(labelConfig[label].key, data.label, excluded, label);
                }}
                label={data.label}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ControlPanel;
