import React from 'react';
import {connect} from 'react-redux';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import Grid from '../../../components/core/Grid';
import {
  gridDataSelector, isLoading, columnsMetadataSelector, metaDataSelector,
  snackbarListSelector, noRowDataMsgSelector, hasServiceErrorOccurredSelector
} from '../../../selectors/pages/reports/myReports';
import {labelSelector} from '../../../selectors/app';
import { pageIdSelector } from '../../../selectors/pages';
import Constants from '../../../constants/appConstants';
import WithLoader from '../../WithLoader';
import {getAppRoute} from '../../../utils/commonUtils';
import {Conditional} from '../../../components/core/Conditional';
import {addAppContext} from '../../../actions/app';
import { mySavedReportsDataSelector } from '../../../selectors/app/reports';
import {setReportDataToDelete} from '../../../actions/page/reports';
import Snackbar from '../../../components/core/Snackbar';
import {isReportDeletionProgressSelector} from '../../../selectors/pages/reports/allReports';
import {ThreeSquaresLoader} from '../../../components/core/Loaders';
import DeleteReportConfirmation from './DeleteReportConfirmation';
import withGrid from '../../../components/hoc/withGrid';
import {getNewWidthedColumns} from '../../../helpers/gridFund';
import {getUpdatedColumns} from '../utils';
import {addPageData} from '../../../actions/page';
import './index.scss';
import {setCustomReportCrudResponse} from "../../../actions/app/reports";

export const MyReportsGrid = (props) => {
  const {
    gridData, columnsMetadata, columnDefs, loading, labels, setSelectedSavedReportData,
    history, mySavedReports, snackbarList, pageId, isReportDeletionProgress,
    noRowDataMsg, hasServiceErrorOccurred, updateColumnMetaData, handleCloseSnackbar
  } = props;
  const config = {
    enableRowGroup: true,
    enableHandleGrouping: true,
    groupDefaultExpanded: -1,
    enableServerSideSorting: true,
    suppressScrollOnNewData: true,
    groupSuppressAutoColumn: true,
    enableServerSideFilter: false,
    context: { // custom prop
      suppressGroupCheckbox: false
    },
    sortingOrder: ['desc', 'asc'],
    groupRowAggNodes: null,
    height: 500,
    floatingFiltersHeight: 0
  };

  const onViewAllReports = () => {
    history.push(getAppRoute(Constants.ALL_REPORTS_TAB_ROUTE));
  };

  const handleCellClick = (event) => {
    const {viewId: customReportViewId, reportName, description} = event.data;
    if(event.colDef.colId === 'deleteReport') {
      props.setReportDataToDelete({
        customReportViewId,
        reportName
      });
    } else {
      const {request: requestPayLoad} =
        mySavedReports.find(({viewId}) => viewId === customReportViewId);
      setSelectedSavedReportData({
        selectedSavedReportData: {
          reportName,
          reportDescription: description,
          savedReportViewId: customReportViewId,
          requestPayLoad
        }
      });
      history.push(getAppRoute(Constants.SAVED_REPORT_ROUTE));
    }
  };

  const dispatchColumnWidthChanged = (columns) => {
    const updatedColumns = getUpdatedColumns(columns);
    const currentView = {columns: columnsMetadata};
    const updatedColMetadata = getNewWidthedColumns(updatedColumns, currentView);
    updateColumnMetaData(updatedColMetadata);
  };

  const updatedProps = {
    pageId,
    data: gridData,
    columnDefs,
    loading,
    labels,
    dispatchCellClick: handleCellClick,
    dispatchColumnWidthChanged,
    noRowDataSelector: noRowDataMsg,
    additionalNoRowsOverlayCompParams: {
      pageId,
      hasServiceErrorOccurred,
      handleClickNoRows: onViewAllReports,
      reportsTab: labels && labels.tkReportsTab,
    },
    config
  };

  return (
    <WithLoader isSkipInitialLoading={true}>
      <Conditional condition={!isEmpty(columnDefs)}>
        <div data-testid="myreportgrid-container" className="myReports">
          <TransitionGroup component={null}>
            {snackbarList.length > 0 && snackbarList.map((data, idx) => {
              return (
                <CSSTransition
                  key={idx}
                  classNames="snackbar__transition"
                  timeout={Constants.CSS_DURATION_HORSE}
                >
                  <Snackbar
                    id={data.id}
                    labels={labels}
                    type={data.type}
                    affix={data.affix}
                    msgCopy={data.msgCopy}
                    autoHide={data.autoHide}
                    showCloseCTA={data.showCloseCTA}
                    showIcon={data.showIcon}
                    onSnackbarClose={handleCloseSnackbar}
                  />
                </CSSTransition>
              );
            })}
          </TransitionGroup>
          {
            isReportDeletionProgress &&
              <ThreeSquaresLoader />
          }
          <div className={cn('myReports__grid', {'myReports__grid--disable': isReportDeletionProgress})}>
            {
              withGrid(Grid)(updatedProps)
            }
          </div>
          <DeleteReportConfirmation />
        </div>
      </Conditional>
    </WithLoader>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setSelectedSavedReportData: (data) => {
    dispatch(addAppContext(data));
  },
  setReportDataToDelete: (data) => {
    dispatch(setReportDataToDelete(data));
  },
  updateColumnMetaData: (columnsMetadata) => {
    dispatch(addPageData({columnsMetadata}));
  },
  handleCloseSnackbar: () => {
    dispatch(setCustomReportCrudResponse(null));
  }
});

const mapStateToProps = (state) => ({
  pageId:pageIdSelector(state),
  columnDefs: metaDataSelector(state),
  columnsMetadata: columnsMetadataSelector(state),
  gridData: gridDataSelector(state),
  mySavedReports: mySavedReportsDataSelector(state),
  snackbarList: snackbarListSelector(state),
  labels: labelSelector(state),
  loading: isLoading(state),
  noRowDataMsg: noRowDataMsgSelector(state),
  hasServiceErrorOccurred: hasServiceErrorOccurredSelector(state),
  isReportDeletionProgress: isReportDeletionProgressSelector(state)
});

MyReportsGrid.propTypes = {
  pageId:PropTypes.string,
  gridData: PropTypes.array,
  labels: PropTypes.object,
  loading: PropTypes.bool,
  isReportDeletionProgress: PropTypes.bool,
  columnsMetadata: PropTypes.object,
  columnDefs: PropTypes.object,
  setSelectedSavedReportData: PropTypes.func,
  mySavedReports: PropTypes.array,
  history: PropTypes.object,
  snackbarList: PropTypes.array,
  setReportDataToDelete: PropTypes.func,
  noRowDataMsg: PropTypes.string,
  hasServiceErrorOccurred: PropTypes.bool,
  updateColumnMetaData: PropTypes.func,
  handleCloseSnackbar: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(MyReportsGrid);
