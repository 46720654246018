import moment from 'moment/moment';
import isNumber from 'lodash/isNumber';
import get from 'lodash/get';
import { excelExportFooterConfiguration } from '../../../configs/excelExport/exportFooter';
import { gridCompositeFormatter } from '../../../utils/portfolioGridFormatter';
import { COLUMN_COLID, SORT } from '../../../constants/pageConstants';

export const getFilterByMappedData = (columnDefs, filterModel) => {
  if (!filterModel || !Object.keys(filterModel).length) return [];
  return Object.keys(filterModel).map((key) => {
    const data = columnDefs.find((column) => column.colId === key);
    return { ...data, colId: data.headerName, term: filterModel[key].filter };
  });
};

export const getAllDisplayedColumns = (allDisplayedColumns) => {
  const columnsToSkip = [COLUMN_COLID.TRADE, COLUMN_COLID.GROUP];
  return allDisplayedColumns.filter(({ colId }) => !columnsToSkip.includes(colId));
}

/**
 * given `gridColumnApi`, fn gets the columns to use for excel export.
 * all displayed columns will be except for some columns that are to be skipped 
 */
export const getExportColumns = (gridColumnApi) => {
  return getAllDisplayedColumns(get(gridColumnApi, 'excelCreator.columnModel.displayedColumns', []));
};

/** utils to format filename for excel export */
export const getFormattedFilename = (filename) => {
  return `${filename.trim()}_${moment().format('DDMMMYYYY')}`.toUpperCase();
}

/** Default process cell callback for excel export. */
const defaultProcessCellCallback = ({
  value,
  node: { data },
  column: { colDef } = {},
}) => {
  const formatters = colDef.formatters
    ? colDef.formatters
        .replace(/(\s*,\s*|^)nullValueFormatter(\s*,\s*|$)/, "")
        .replace(/,+/g, ",")
        .trim()
    : [];
  if (formatters.length) {
    const colConfig = { ...colDef, formatters };
    const formattedValue =
      value != null && (value.length || isNumber(value))
        ? gridCompositeFormatter({
            data,
            colDef: colConfig,
            value: String(value),
          })
        : value;
    return formattedValue;
  }
  return value;
};

/** Generates the configuration object for Excel export. */
export const getExcelExportConfigs = (props) => {
  const {
    columnDefs,
    currentView,
    exportColumnsFunc,
    filterModel,
    footer,
    processCellCallback,
    sheetName,
    labels,
    skipGroups,
  } = props;

  const filename = get(currentView, "label", "");
  const filterByMappedData = getFilterByMappedData(columnDefs, filterModel);
  const columnSortingStateMappedData = columnDefs
    .filter((column) => column.sort === SORT.ASC || column.sort === SORT.DESC)
    .map((column) => ({ ...column, colId: column.headerName }));

  // if footer was not provided, use the default footer
  const footerContent = 
    footer ||
    excelExportFooterConfiguration({
      ...props,
      filterByMappedData,
      columnSortingStateMappedData,
    });

  return {
    exportColumnsFunc: exportColumnsFunc || getExportColumns,
    fileName: getFormattedFilename(filename),
    footer: footerContent,
    processCellCallback: processCellCallback || defaultProcessCellCallback,
    sheetName: sheetName || labels.tkNavMenuCurrentTrades,
    skipGroups: skipGroups || true,
  };
};

/**
 * Comparator checks for empty values,
 * yields equality if both are empty, `undefined` or `null`
 * if one is empty, it is considered less than the other
 * if both are not empty, it returns `null`
 */
const emptyValuesComparator = (valueA, valueB, isDescending) => {
  if (typeof valueA !== 'boolean' && typeof valueB !== 'boolean') {
    if (!valueA && !valueB) {
      return 0;
    }
    if (!valueA) {
      return isDescending ? -1 : 1;
    }
    if (!valueB) {
      return isDescending ? 1 : -1;
    }
  }
  return null;
};

/**
 * comparator for date values leveraging momentjs
 */
export const dateComparator = (valueA, valueB, ...rest) => {
  const isDescending = rest[rest.length - 1];
  const emptyValueCheckResult = emptyValuesComparator(valueA, valueB, isDescending);
  if (emptyValueCheckResult != null) {
    return emptyValueCheckResult;
  }
  return moment(valueA).diff(moment(valueB));
}

/**
 * comparator for string values - case insensitive - using localeCompare
 * fn is the default comparator for grid columns
 */
export const stringComparator = (valueA, valueB, ...rest) => {
  const isDescending = rest[rest.length - 1];
  const emptyValueCheckResult =  emptyValuesComparator(valueA, valueB, isDescending);
  if (emptyValueCheckResult != null) {
    return emptyValueCheckResult;
  }
  // since stringComparator is the default, 
  // it is possible that value is boolean, number or other non string types
  if (typeof valueA === 'string' || typeof valueB === 'string') {
    return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
  }
  return valueA - valueB;
};

/**
 * comparator for numeric values
 * comparator is applied on numeric columns identified as:
 * numericSortColumn, navColumn, variableNumericColumn
 */
export const numericComparator = (valueA, valueB, ...rest) => {
  const isDescending = rest[rest.length - 1];
  const emptyValueCheckResult = emptyValuesComparator(valueA, valueB, isDescending);
  if (emptyValueCheckResult != null) {
    return emptyValueCheckResult;
  }
  return Number.parseFloat(valueA) - Number.parseFloat(valueB);
};