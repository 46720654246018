import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

const GearButton = ({disabled, active, onClick}) => {
  const className = `transparent-button icon-gear ${active ? 'active' : ''}${disabled ? 'disabled' : ''}`;
  return (
    <button className={className} onClick={() => !disabled && onClick(!active)} />
  );
};

GearButton.defaultProps = {
  onClick: () => false
};

GearButton.propTypes = {
  disabled: PropTypes.bool,
  active: PropTypes.bool,
  onClick: PropTypes.func
};

export default GearButton;
