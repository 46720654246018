import get from 'lodash/get';
import {createSelector} from 'reselect';
import {labelSelector, navBarSelector, reportingPermitRolesSelector} from '../../../app';
import AppConstants from '../../../../constants/appConstants';
import {reportsConfigDataSelector} from '../../../app/reports';
import translator from '../../../../services/translator';
import {allowFeaturesSelector} from '../../../user';

const {translate: t} = translator;

// ADD selector only pagaData
export const groupColumns = state => state.pageData.groupColumns;
export const columnsMetadata = state => {
  const {allReportColumnsMetadata: {columns = []} = {}} = state.pageData;
  return columns;
}
export const isReportPageLoadingSelector = state => state.pageData.isReportPageLoading;
export const isReportDeletionProgressSelector = state => state.pageData.isReportDeletionProgress;

// ADD selector only pageContext
export const isLoading = state => state.pageContext.loading; // Don't assign any fallback

export const rollBackToLegacyReportSelector = createSelector(
  allowFeaturesSelector,
  (allowFeatures) => (!allowFeatures.includes(AppConstants.REPORTINGNEW))
);

export const metaDataSelector = createSelector(
  columnsMetadata,
  labelSelector,
  (metaData, labels) => {
    return metaData.map( column => {
      const columnData = {...column};
      columnData.headerTooltip = labels[column.headerName];
      columnData.headerName = labels[column.headerName];
      if(column.category) {
        columnData.category = labels[column.category];
      }
      return columnData;
    });
  }
);

export const reportSubMenuItemsSelector = createSelector(
  navBarSelector,
  (navBar) => {
    const navRootConfig = get(navBar, 'navRootConfig', []);
    const reportsNavConfig = navRootConfig.find(({routingPathId}) =>
      routingPathId === AppConstants.REPORTS
    );
    return reportsNavConfig ? reportsNavConfig.subMenu : [];
  }
);

/**
 * Selector to get all reports page grid data
 * Data will be sorted in ASC order and
 * a report will be flagged as 'NEW' when user can view legacy report
 */
export const allReportsGridDataSelector = createSelector(
  reportsConfigDataSelector,
  reportSubMenuItemsSelector,
  rollBackToLegacyReportSelector,
  reportingPermitRolesSelector,
  (reportsConfigData, reportSubMenuItems, rollBackToLegacyReport, reportingPermitRoles) => {
    const reports = get(reportsConfigData, 'reports', []);
    const validReports = reports.filter(({ legacyReportId, newReportId }) =>
      reportSubMenuItems.some(({ routingPathId }) => {
        return [legacyReportId, newReportId].includes(routingPathId);
      })
    );

    return validReports
      .map((item) => {
        const hasOldReportAccess = reportingPermitRoles.includes(`${item.legacyReportId}.legacy`);
        const hasNewReportAccess = reportingPermitRoles.includes(`${item.legacyReportId}.new`);

        // Flag the report as 'NEW' when user can view legacy report
        const newFlagLabel = t('tkNew').toUpperCase();
        const type = (!rollBackToLegacyReport && hasOldReportAccess)? newFlagLabel : '';

        return {
          ...item,
          type,
          report: t(item.reportTitleKey),
          description: t(item.reportTitleDescKey),
          // controls whether user should see old report
          displayLegacyReport: rollBackToLegacyReport || !hasNewReportAccess
        };
      })
      .sort(
        (a, b) =>
          a.reportGroup.localeCompare(b.reportGroup) ||
          a.report.localeCompare(b.report)
      );
  }
);
