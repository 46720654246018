import React, {useEffect, useState, useRef} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import isEmpty from 'lodash/isEmpty';
import {Conditional} from '../../core/Conditional';
import translator  from '../../../services/translator';
import excelStyleConfig from '../../../configs/excelExport/excelStylesConfig';
import {isInvalidFilter} from '../../../utils/validators/validators';
import {animationFunc} from '../../../utils/tradeSelectAnimation';
import {getDraftTradeData, noop } from '../../../helpers/gridFund';
import {FUND_DOMICILE} from '../../../constants/pageConstants';

const {translate: t} = translator;

const NOGROUPING_FIELD_VALUE = "none";
const withGrid =  WrapperComponent => {
  const Sub = (props) => {

    const {
      selectedView, selectedGroup, startExcelExport, filterVisibility, data, filterBy, additionalNoRowsOverlayCompParams,
      noRowDataSelector, columnDefs, loading, labels, components, frameworkComponents, config, selectedRows, aggregatedData,
      collapseState, enableAddTradeToBlotter = false, filteredDataLength, colDef, gsToolKit, footerInfo, destroyFilters,
      domLayout
    } = props;

    let grid = useRef();

    const {
      dispatchSortChange = noop,
      dispatchPageChange = noop,
      dispatchColumnMoved = noop,
      dispatchColumnRemove = noop,
      dispatchFilterChange = noop,
      dispatchFilterFocus = noop,
      dispatchGroupExpandCollapseChange = noop,
      dispatchColumnWidthChanged = noop,
      dispatchRowSelection = noop,
      dispatchRowClick = noop,
      dispatchCellClick = noop,
      dispatchGridReady = noop,
      dispatchLoadStart = noop,
      dispatchLoadEnd = noop,
      dispatchFilterApplied = noop,
      dispatchFirstDataRendered = noop,
      dispatchFilterChangeWithError = noop
    } = props;


    const methods = {
      // groupRowAggNodes: this.groupRowAggNodes,
      // onBtExport: onExport
    };

    const exportExcel = () => {
      const exportFn = typeof props.excelExportConfig === 'function' ? props.excelExportConfig() : props.excelExportConfig; // this function need be called on demand on each render
      const { footer, fileName, sheetName, skipGroups, processCellCallback, alignment, exportColumnsFunc } = exportFn;
      const excelExportParams = {
        columnKeys: !exportColumnsFunc ? null : exportColumnsFunc(grid.gridApi),
        skipRowGroups: skipGroups,
        fileName: !fileName ? null : fileName,
        sheetName: !sheetName ? null : sheetName,
        // TODO: when we are live with datagrid, consider putting footer in a separate sheet
        appendContent: footer,
        alignment,
        processCellCallback: params => {
          const { colDef } = params.column;
          if (colDef && colDef.dataType === 'date') {
            if (!colDef.cellClass.includes('excel-date-format')) {
              colDef.cellClass.push('excel-date-format');
            }
            if (params.value) {
              return moment.utc(params.value).toISOString();
            } else if (!params.value) {
              return params.value;
            }
          }
          return processCellCallback(params);
        },
      };
      grid.gridApi.exportDataAsExcel(excelExportParams);
    };

    const [isFilterInvalid, setIsFilterInvalid] = useState(false);
    const [rowGroupingState, setRowGroupingState] = useState([]);
    const [isGridReady, setIsGridReady] = useState(false);

    const setGridNodeState = () => {
      const prevSelectedRows = [];
      grid && grid.gridApi && grid.gridApi.forEachNode(node => {
        node.group && prevSelectedRows.push({ key: node.key, expanded: node.expanded});
      });
      setRowGroupingState(prevSelectedRows);
    };

    const handleRowSelection = (grid) => {
      const { selectedRowsComparator } = props;
      if(grid && grid.gridApi){
        grid.gridApi.forEachNode(node => {
          selectedRowsComparator && node.data && node.setSelected(selectedRowsComparator(selectedRows, node.data));
        });
      }
    };

    const handleGroupingAndRowSelction = (grid) => {
      const { selectedRowsComparator, clientGroupBy, groupByField } = props;
      if(grid && grid.gridApi){
        grid.gridApi.forEachNode(node => {
          selectedRowsComparator && node.data && node.setSelected(selectedRowsComparator(selectedRows, node.data));
          if(rowGroupingState.length && node.group){
            const nodeInfo = rowGroupingState.find(group => group.key === node.key);
            if(nodeInfo){
              node.expanded = !!nodeInfo.expanded;
            }
          }
        });
        rowGroupingState.length && grid.gridApi.onGroupExpandedOrCollapsed();

        // Check for Client side Group By
        if (clientGroupBy && !isEmpty(groupByField)) {
          const groups = groupByField.split("/").filter(value => value !== NOGROUPING_FIELD_VALUE);
          grid.gridApi.columnModel.setRowGroupColumns(groups);
        }
      }
    };


    let filterModel;
    useEffect(() => {
      filterModel = grid.api && grid.api.getFilterModel && grid.api.getFilterModel();
      !(isEmpty(filterModel)) && grid.clearFilters();
    }, [selectedView]);

    useEffect(() => {
      isGridReady && grid && grid.gridApi && grid.toggleOverlay && grid.toggleOverlay(loading, isFilterInvalid ? t('tkCopyPort17') : noRowDataSelector);
      if (loading) {
        dispatchLoadStart();
      } else {
        dispatchLoadEnd(props);
      }
    }, [data, loading, isGridReady]);

    /**
     * Handle Grouping if enableHandleGrouping is true
     */
    useEffect(() => {
      grid.gridApi && grid.gridApi.sizeColumnsToFit();
      if(!config.enableServerSideFilter) {
        grid.api && grid.api.setFilterModel && grid.api.setFilterModel(grid.api.getFilterModel());
      }
      if(config.enableHandleGrouping){
        handleGroupingAndRowSelction(grid);
      } else {
        handleRowSelection(grid);
      }
    }, [selectedRows, data, columnDefs]);

    useEffect(() => {
      !loading && startExcelExport !== undefined && exportExcel();
    }, [startExcelExport]);


    useEffect(() => {
      grid.toggleGroupExpandCollapse && grid.toggleGroupExpandCollapse(collapseState);
      setGridNodeState();
    }, [collapseState, selectedGroup]);

    useEffect(() => {
      if (grid.api && grid.api.getFilterModel) {
        const filters = grid.api.getFilterModel();
        Object.keys(filters).forEach(key => {
          grid.destroyFilter && grid.destroyFilter(key);
        });
      }
      grid.setFilterModel && grid.setFilterModel(null);
    }, [destroyFilters]);

    useEffect(() => {
      grid.toggleFilterVisibility && grid.toggleFilterVisibility(filterVisibility);
      if(isFilterInvalid){
        setIsFilterInvalid(false);
      }
    }, [filterVisibility]);

    useEffect(() => {
      if(!config.enableServerSideFilter){
        if(filteredDataLength === 0){
          grid.toggleOverlay && grid.toggleOverlay(false, true);
        } else if(filteredDataLength){
           grid.toggleOverlay && grid.toggleOverlay(false, false);
        }
      }
    }, [filteredDataLength, isFilterInvalid]);

    const handleSortChanged = (colId, columnName, order) => {
      dispatchSortChange(colId, columnName, order, props);
    };

    const handleFilterChanged = (filterModel) => {
      if (isInvalidFilter(filterModel)) {
        setIsFilterInvalid(true);
        dispatchFilterChangeWithError();
        return;
      }
      setIsFilterInvalid(false);
      dispatchFilterChange(filterModel, grid.gridApi.getDisplayedRowCount());
    };

    /* we are keeping this logic here as we are using this functionality
       in fund finder and portfolio both. This may also be required in some other functionality
     */
    const handleToggleTrade = (isSelected, node) => {
      const {dispatchAddTrade, dispatchRemoveTrade, suppressTradeAnimation } = props;
      if(!node.data) return;

      if(isSelected){
        if(!suppressTradeAnimation){// provisional prop to disable animation
          let animateText = (node.data.fundDomicile === FUND_DOMICILE.US )
            ? node.data.cusip : node.data.isin;

          if(!animateText){// Fallback logic
            animateText = node.data.fundNumber || (node.data.cusip || node.data.isin);
          }

          animationFunc(animateText, node.event);
        }
        dispatchAddTrade(getDraftTradeData(node.data));
      } else {
        dispatchRemoveTrade(getDraftTradeData(node.data));
      }
    };

    const handleGroupExpandCollapse = () => {
      dispatchGroupExpandCollapseChange();
    };

    const handleColumnMoved = (columns, params) => {
      dispatchColumnMoved(columns, params, props);
    };

    const handlePageChange = () => {
      dispatchPageChange();
    };

    const handleGridReady = () => {
      setIsGridReady(true);
      dispatchGridReady(grid);
    };

    const handleColumnWidthChanged = (data) => {
      dispatchColumnWidthChanged(data, props);
    };

    const handleColumnVisibility = (columnId, visibility) => {
      dispatchColumnRemove(columnId, visibility, props);
    };

    const handleFilterFocus = (column) => {
      dispatchFilterFocus(column);
    };

    const handleCellClicked = (event) => {
      dispatchCellClick(event);
    };

    const handleFirstDataRendered = (event) => {
      dispatchFirstDataRendered(event);
    };

    const handleRowClicked = () => {
      dispatchRowClick();
    };

    const handleFilterApplied = (filterModel, totalFilteredRows) => {
      dispatchFilterApplied(filterModel, totalFilteredRows, props);
    };

    const handleCheckboxSelected = (node, isSelected) => {
      enableAddTradeToBlotter && handleToggleTrade(isSelected, node);
      dispatchRowSelection(isSelected, data, node);
    };

    const handleDisplayColumnsChanged = () => {
      // dispatchColumnWidthChanged();
    };

    const handleRowGroupToggle = () => {
      setGridNodeState();
    };
    return (
      <Conditional condition={columnDefs.length > 0}>
        <WrapperComponent
          ref={(api) => grid = api}
          gsToolKit={gsToolKit}
          columnDefs={columnDefs}
          rowData={isFilterInvalid ? [] : data}
          methods={methods}
          filterBy={filterBy}
          components={components}
          aggregatedData={aggregatedData}
          //  events={events}
          frameworkComponents={frameworkComponents}
          onColumnVisible={handleColumnVisibility}
          onSortChanged={handleSortChanged}
          onColumnMoved={handleColumnMoved}
          onFilterChanged={handleFilterChanged}
          onFilterApplied={handleFilterApplied}
          onColumnFilterFocus={handleFilterFocus}
          onRowGroupOpened={handleRowGroupToggle}
          onGridReady={handleGridReady}
          config={config}
          onColumnWidthChanged={handleColumnWidthChanged}
          excelStyles={excelStyleConfig}
          noRowMsg={isFilterInvalid ? t('tkCopyPort17') : noRowDataSelector}
          loading={loading}
          labels={labels}
          filterVisibility={filterVisibility}
          onDisplayedColumnChanged={handleDisplayColumnsChanged}
          filterModel={filterModel}
          onCellClicked={handleCellClicked}
          onRowClicked={handleRowClicked}
          onGroupExpandCollapse={handleGroupExpandCollapse}
          onCheckboxSelected={handleCheckboxSelected}
          onPageChange={handlePageChange}
          onFirstDataRendered={handleFirstDataRendered}
          colDef={colDef}
          footerInfo={footerInfo}
          domLayout={domLayout}
          additionalNoRowsOverlayCompParams={additionalNoRowsOverlayCompParams}
        />
      </Conditional>

    );
  };


  Sub.propTypes =  {
    dispatchSortChange: PropTypes.func,
    dispatchPageChange: PropTypes.func,
    dispatchColumnMoved: PropTypes.func,
    dispatchColumnRemove:  PropTypes.func,
    dispatchFilterChange: PropTypes.func,
    dispatchGroupExpandCollapseChange: PropTypes.func,
    dispatchColumnWidthChanged: PropTypes.func,
    dispatchFilterChangeWithError: PropTypes.func,
    dispatchRowSelection: PropTypes.func,
    dispatchRowClick: PropTypes.func,
    dispatchCellClick: PropTypes.func,
    dispatchFilterFocus: PropTypes.func,
    dispatchGridReady: PropTypes.func,
    dispatchLoadStart: PropTypes.func,
    dispatchAddTrade: PropTypes.func,
    dispatchRemoveTrade: PropTypes.func,
    dispatchLoadEnd: PropTypes.func,
    selectedRowsComparator: PropTypes.func,
    dispatchFirstDataRendered: PropTypes.func,
    selectedView: PropTypes.string,
    selectedRows: PropTypes.array,
    selectedGroup: PropTypes.string,
    startExcelExport: PropTypes.bool,
    excelExportConfig: PropTypes.object,
    filterVisibility: PropTypes.string,
    collapseState: PropTypes.bool,
    enableAddTradeToBlotter: PropTypes.bool,
    data: PropTypes.array,
    filterBy: PropTypes.array,
    noRowDataSelector: PropTypes.func,
    columnDefs: PropTypes.array,
    aggregatedData: PropTypes.array,
    loading: PropTypes.bool,
    labels: PropTypes.object,
    components: PropTypes.object,
    frameworkComponents: PropTypes.object,
    config: PropTypes.object,
    suppressTradeAnimation: PropTypes.bool,
    dispatchFilterApplied: PropTypes.func,
    filteredDataLength: PropTypes.number,
    colDef: PropTypes.object,
    gsToolKit: PropTypes.bool,
    footerInfo: PropTypes.any,
    destroyFilters: PropTypes.any,
    domLayout: PropTypes.string,
    additionalNoRowsOverlayCompParams: PropTypes.object,
    clientGroupBy: PropTypes.bool,
    groupByField: PropTypes.string
  };
  return Sub;
};

withGrid.PropTypes = {
  WrapperComponent: PropTypes.element.required
};

export default withGrid;
