import actionTypes from './actionTypes';

export const isPageLoading = data => (
  {
    type: actionTypes.IS_PAGE_LOADING,
    data: {'isPageLoading': data}
  }
);

export const addPageData = data => (
  {
    type: actionTypes.ADD_PAGE_DATA,
    data: {...data}
  }
);

export const clearPageData = () => (
  {
    type: actionTypes.CLEAR_PAGE_DATA
  }
);

export const addPageContext = data => (
  {
    type: actionTypes.ADD_PAGE_CONTEXT,
    data: {...data}
  }
);


export const clearPageContext = () => (
  {
    type: actionTypes.CLEAR_PAGE_CONTEXT
  }
);

export const updatePageId = (data, refreshPage = false) => (
  {
    type: actionTypes.UPDATE_PAGE_ID,
    data: {'pageId': data, refreshPage}
  }
);
