import React from 'react';
import { Select } from '@gs-ux-uitoolkit-react/select';
import PropTypes from 'prop-types';

const DropdownField = props => {
  const { value, config, handleChange, options } = props;

  const handleSelectChange = newValue => {
    handleChange({
      value: newValue.selectedValue,
      config,
    });
  };

  return <Select options={options} onSelect={handleSelectChange} selectedValue={value} clearable={false} className='profile-dropdown' />;
};

DropdownField.propTypes = {
  handleChange: PropTypes.func,
  options: PropTypes.array,
  config: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default DropdownField;
