/* ####################################################################################

    THIS MODULES IS DESIGNED FOR REUSABLE CONTAINER COMPONENT. SO YOU MUST FOLLOW SAME KEYS
    AND SELECTOR NAME. YOU CAN OVERRIDE ALL PROPS IF YOU WANT. YOU SHOULD PASS
    ID IN YOUR MODULE AS PROPS IN ORDER TO GET IN ACTIONS/ACTIONCREATORS AND SELECTORS

######################################################################################### */
import { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '../../components/core/Grid';
import {
  columnsMetadata,
  filterVisibility,
  filteredDataLength,
  getFilterModel,
  getFilters,
  getLastEvent,
  groupByFieldNew,
  isLoading,
  startExcelExport
} from './selector';
import { gridMapDispatchToProps } from './mapDispatchToProps';
import withGrid  from '../../components/hoc/withGrid';
import { labelSelector, selectedFirms, reportDisclaimerSelector } from '../../selectors/app';
import { userSelector } from '../../selectors/user';
import { getExcelExportConfigs } from './helper';

export const GridModule = props => {
  const config = {
    groupRowAggNodes: props.groupRowAggNodes,
    enableHandleGrouping: true,
    groupDefaultExpanded: props.collapseState === true ? 0 : -1,
    enableServerSideSorting: true,
    suppressScrollOnNewData: true,
    groupSuppressAutoColumn: true,
    context: { // custom prop
      suppressGroupCheckbox: true
    },
    enableServerSideFilter : false,
    sortingOrder: ['desc', 'asc'],
    noRowsOverlayComponentParams : {
      noRowMsg: props.isInvalidFilter ? props.labels.tkCopyPort17 : props.noRowDataSelector
    },
    ...props.gridConfig,
  };

  const [startTime, setStartTime] = useState(null);
  const onDispatchLoadStart = () => setStartTime(new Date().getTime());
  const onDispatchLoadEnd = () => {
    const {lastEvent, data, columnDefs, currentView, filterBy} = props;
    lastEvent && props.dispatchGridLoadEnd(lastEvent, data.length, columnDefs, currentView.name, filterBy, startTime);
  };

  const updatedProps = {
    config,
    excelExportConfig: props.excelExportConfig || getExcelExportConfigs({ ...props }),
    dispatchLoadEnd: onDispatchLoadEnd,
    dispatchLoadStart: onDispatchLoadStart,
    ...props,
  };

  return withGrid(Grid)(updatedProps);
};

const mapStateToProps = (state, ownProps) => {
  const { id } = ownProps;
  return {
    filterBy: getFilters(state, id),
    filterModel: getFilterModel(state, id),
    labels: labelSelector(state, id),
    loading: isLoading(state, id),
    columnsMetadata: columnsMetadata(state, id),
    startExcelExport: startExcelExport(state, id),
    groupByField: groupByFieldNew(state, id),
    filterVisibility: filterVisibility(state, id),
    disclaimer: reportDisclaimerSelector(state, id),
    userInformation: userSelector(state, id),
    selectedFirms: selectedFirms(state),
    lastEvent: getLastEvent(state, id),
    filteredDataLength: filteredDataLength(state, id),
    ...ownProps
  };
};

GridModule.propTypes = {
  filterBy: PropTypes.array,
  filterModel: PropTypes.object,
  labels: PropTypes.object,
  loading: PropTypes.bool,
  columnsMetadata: PropTypes.array,
  startExcelExport: PropTypes.object,
  groupByField: PropTypes.string,
  filterVisibility:PropTypes.string,
  disclaimer: PropTypes.array,
  selectedFirms: PropTypes.array,
  filterByMappedData: PropTypes.array,
  columnSortingStateMappedData: PropTypes.array,
  userInformation: PropTypes.object,
  filteredDataLength: PropTypes.number
};

GridModule.defaultProps = {
  enableModuleCleanUpOnDestroy: true,
  enableFloatingfilter: true,
  groupByField: '',
  columnDefs: [],
  data: [],
};
export default connect(mapStateToProps, gridMapDispatchToProps)(GridModule);
