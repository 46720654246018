import { useCallback } from 'react';
import endPointsMapper from '../../../../../configs/endPointsMapper';
import useUserMaintenance from '../../context';
import useFetch from '../useFetch';
import useSnackBar from '../useSnackbar';
import useLoader from '../useLoader';
import useGetUserDetails from '../useGetUserDetails';
import useGetOrganizationAccounts from '../useGetOrganizationAccounts';
import { ADD_UPDATE_USER_DETAILS, SAVE_CHANGES, SAVE_BTN_CLICKED } from '../../actions';
import { AVAILABLE_ACCOUNTS } from '../../constants';
import translator from '../../../../../services/translator';

const { translate: t } = translator;

const useSaveUserDetails = () => {
  const {
    dispatch: ctxDispatch,
    state: {
      userDetails: { isMosaicInternal, organizationId: extOrganizationId },
      activeAccountsType,
      selectedOrganization: { organizationId },
      organizationAccounts = {},
    },
    storeValues: { isInternalUser },
  } = useUserMaintenance();
  const { openSnackbar } = useSnackBar();

  const { getUserDetails } = useGetUserDetails();

  const { getOrganizationAccounts } = useGetOrganizationAccounts();

  const onSuccess = useCallback(
    async (response, payLoad) => {
      ctxDispatch({ type: ADD_UPDATE_USER_DETAILS, payload: { userDetails: payLoad.userSaveDetails[0], success: true } });
      ctxDispatch({ type: SAVE_CHANGES });
      ctxDispatch({ type: SAVE_BTN_CLICKED, payload: { saveBtn: false, savedRecentEdits: true } });
      const allowOrganizationSearch = isInternalUser && isMosaicInternal;
      if (allowOrganizationSearch || (!allowOrganizationSearch && activeAccountsType === AVAILABLE_ACCOUNTS)) {
        const orgId = allowOrganizationSearch ? organizationId : extOrganizationId;
        if ((orgId && !allowOrganizationSearch) || (orgId && allowOrganizationSearch && organizationId in organizationAccounts)) {
          await getOrganizationAccounts({
            userId: payLoad.userSaveDetails[0].id,
            organizationId: orgId,
            type: activeAccountsType.toUpperCase(),
          });
        }
        if (!allowOrganizationSearch && activeAccountsType === AVAILABLE_ACCOUNTS) {
          await getUserDetails(payLoad.userSaveDetails[0].id);
        }
      } else {
        await getUserDetails(payLoad.userSaveDetails[0].id);
      }
      openSnackbar({ type: 'success', message: t('tkChangesSavedSuccessfully') });
    },
    [isInternalUser, isMosaicInternal, getUserDetails, activeAccountsType, organizationId, extOrganizationId, organizationAccounts]
  );

  const onError = useCallback(() => {
    openSnackbar({ type: 'error', message: t('tkAnErrorPleaseTryAgain') });
    ctxDispatch({ type: SAVE_BTN_CLICKED, payload: { saveBtn: false, savedRecentEdits: false } });
  }, []);

  const { doFetch, loading } = useFetch(endPointsMapper.EDIT_SAVE_USER_SEARCH_DETAILS, onSuccess, onError);
  useLoader(loading);

  return {
    onSaveUserDetails: doFetch,
  };
};

export default useSaveUserDetails;
