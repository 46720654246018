/* ####################################################################################

    THIS MODULES IS DESIGNED FOR REUSABLE CONTIANER COMPONENT. SO YOU MUST FOLLOW SAME KEYS
    AND SELECTOR NAME. YOU CAN OVERRIDE ALL PROPS IF YOU WANT. YOU SHOULD PASS
    ID IN YOUR MODULE AS PROPS IN ORDER TO GET IN ACTIONS/ACTIONCREATORS AND SELECTORS

######################################################################################### */
import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import Grid from '../../../components/core/Grid';
import {
  allReportsGridDataSelector, isLoading,
  isReportPageLoadingSelector, metaDataSelector
} from '../../../selectors/pages/reports/allReports';
import {labelSelector} from '../../../selectors/app';
import {addAppData} from '../../../actions/app';
import Constants from '../../../constants/appConstants';
import {REPORT_ANALYTICS_DATA} from '../../../constants/pageConstants';
import WithLoader from '../../WithLoader';
import {getAppRoute} from '../../../utils/commonUtils';
import {eventAnalytics} from '../../../actions/sagas';
import {reportAnalyticsEventData} from '../analytics';
import {Conditional} from '../../../components/core/Conditional';
import {addPageData} from '../../../actions/page';
import './index.scss';

export const AllReportsGrid = props => {
  const {
    data, columnDefs, loading, labels, setIsReportPageLoadingFlag, dispatchAppData,
    dispatchReportSelectAnalyticsEvent, history, isReportPageLoading
  } = props;

  const config = {
    enableRowGroup: true,
    enableHandleGrouping: true,
    groupDefaultExpanded: -1,
    enableServerSideSorting: false,
    suppressScrollOnNewData: true,
    groupSuppressAutoColumn: true,
    enableServerSideFilter: false,
    context: { // custom prop
      suppressGroupCheckbox: false,
    },
    sortingOrder: ['desc', 'asc'],
    groupRowAggNodes: null,
    height: 500
  };

  const dispatchAnalytics = (analyticsData) => {
    const analyticsEventData = reportAnalyticsEventData(
      REPORT_ANALYTICS_DATA.VIEW_REPORT,
      {
        reportCategory: analyticsData.reportGroup,
        reportName: analyticsData.report,
        reportType: analyticsData.reportType
      }
    );
    dispatchReportSelectAnalyticsEvent(analyticsEventData);
  };

  const handleCellClick = ({ data }) => {
    if (!data) return;

    setIsReportPageLoadingFlag({ isReportPageLoading: true });
    const { report, reportGroup, description, legacyReportId, newReportId, displayLegacyReport } = data;

    // default to new report and update to old only when required
    let reportUrl = newReportId;
    let reportType = REPORT_ANALYTICS_DATA.MOSAIC_DEFAULT_REPORT;
    if (displayLegacyReport) {
      reportUrl = legacyReportId;
      reportType = REPORT_ANALYTICS_DATA.GTAM_OLD_REPORT;
    }

    const analyticsData = { report, reportGroup, reportType };
    dispatchAnalytics(analyticsData);
    dispatchAppData({ report, description, fromReport: true });

    return history.push(`${getAppRoute(Constants.REPORTS_ROUTE)}/${reportUrl}`);
  };

  return (
    <WithLoader isSkipInitialLoading={true}>
      <div data-testid="reportgrid-container" className="allreports">
        <Conditional condition={!isReportPageLoading && !isEmpty(columnDefs)}>
          <div className="allreports__grid">
            <Grid
              rowData={data}
              columnDefs={columnDefs}
              loading={loading}
              labels={labels}
              onCellClicked={handleCellClick}
              config={config}/>
          </div>
        </Conditional>
      </div>
    </WithLoader>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setIsReportPageLoadingFlag: data => dispatch(addPageData(data)),
  dispatchAppData: data => dispatch(addAppData(data)),
  dispatchReportSelectAnalyticsEvent: (data) => {
    dispatch(eventAnalytics(data));
  }
});

const mapStateToProps = (state) => ({
  columnDefs: metaDataSelector(state),
  data: allReportsGridDataSelector(state),
  dispatchAppData: PropTypes.func,
  labels: labelSelector(state),
  loading: isLoading(state),
  isReportPageLoading: isReportPageLoadingSelector(state),
});

AllReportsGrid.propTypes = {
  data: PropTypes.array,
  labels: PropTypes.object,
  loading: PropTypes.bool,
  columnDefs: PropTypes.object,
  dispatchAppData: PropTypes.func,
  dispatchReportSelectAnalyticsEvent: PropTypes.func,
  setIsReportPageLoadingFlag: PropTypes.func,
  history: PropTypes.object,
  isReportPageLoading: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(AllReportsGrid);
