import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import cn from 'classnames';
import withGrid from '../../../../../../components/hoc/withGrid';
import Grid from '../../../../../../components/core/Grid';
import useUserMaintenance from '../../../context';
import { MODULES_KEYS } from '../../../../../../constants/pageConstants';
import { orgFundsTabGridHeader } from '../../../selectors';
import translator from '../../../../../../services/translator';
import { FILTER_FUND_GROUPS } from '../../../actions';
import { getSortedStringData } from '../../../../../../utils/sorting';
import './index.scss';

const { translate: t } = translator;

export const selectedRowsComparator = (selectedRows, rowData) => {
  if (!selectedRows.length) return;
  return !!rowData.fundGroupName && rowData.fundGroupName.some(item => selectedRows.includes(item.trim()));
};

const OrgDetailsGrid = ({ columnDefs: columns }) => {
  const {
    state: {
      organizationDetails: { organizationName, funds },
      filters = [],
    },
    dispatch,
  } = useUserMaintenance();

  const [columnDefs, setColumnDefs] = useState(columns);
  const [filterBy, setFilterBy] = useState([]);

  const [gridDataRendered, setGridDataRendered] = useState(false);
  const [sortState, setSortState] = useState({ colId: 'longName', order: 'asc' });

  const fundsGroups = useMemo(() => funds.fundGroups.map(data => data.name.replace(/^./, data.name[0].toUpperCase())).sort(), [funds]);

  const gridData = useMemo(() => {
    const uniqueData = {};
    if (funds && funds.individualShareclasses && funds.individualShareclasses.length) {
      funds.individualShareclasses.forEach(item => {
        if (!uniqueData[item.id]) {
          uniqueData[item.id] = item;
        }
      });
    }
    if (funds && funds.fundGroups && funds.fundGroups.length) {
      funds.fundGroups.forEach(item => {
        if (item.shareclasses && item.shareclasses.length) {
          item.shareclasses.forEach(shareclass => {
            if (!uniqueData[shareclass.id]) {
              uniqueData[shareclass.id] = { ...shareclass, fundGroupName: [item.name.trim()] };
            } else {
              uniqueData[shareclass.id] = {
                ...uniqueData[shareclass.id],
                fundGroupName: uniqueData[shareclass.id].fundGroupName
                  ? [...uniqueData[shareclass.id].fundGroupName, item.name.trim()]
                  : [item.name.trim()],
              };
            }
          });
        }
      });
    }
    return Object.values(uniqueData);
  }, [funds]);

  const noGridRows = useMemo(() => (!gridData.length ? t('tkNoFundsWithOrganization') : ''), [gridData]);

  const dispatchFirstDataRendered = () => {
    if (filters.length) {
      setTimeout(() => {
        setGridDataRendered(true);
      }, 10);
    }
  };

  const selectedFundRows = useMemo(() => {
    return filters && filters.map(filter => filter.trim());
  }, [filters, gridData, gridDataRendered]);

  const sortedData = useMemo(() => {
    const selected = [];
    let unSelected = [];
    if (selectedFundRows.length) {
      gridData.forEach(rowData => {
        if (rowData.fundGroupName && rowData.fundGroupName.some(item => selectedFundRows.includes(item.trim()))) {
          selected.push(rowData);
        } else {
          unSelected.push(rowData);
        }
      });
    } else {
      unSelected = gridData;
    }
    const sorttedSelected = selected.length ? getSortedStringData(selected, sortState.colId, sortState.order === 'asc') : [];
    const sortedUnSelected = getSortedStringData(unSelected, sortState.colId, sortState.order === 'asc');
    return [...sorttedSelected, ...sortedUnSelected];
  }, [gridData, sortState, selectedFundRows]);

  const dispatchSortChange = (colId, _, order) => {
    setSortState({ colId, order });
    setColumnDefs(
      columnDefs.map(column => {
        if (column.colId === colId) {
          return { ...column, sort: order };
        }
        return column;
      })
    );
  };

  const dispatchColumnWidthChanged = col => {
    setColumnDefs(
      columnDefs.map(column => {
        if (column.colId === col[0].colId) {
          return { ...column, ...col[0], suppressSizeToFit: true };
        }
        return column;
      })
    );
  };

  const dispatchFilterChange = filterModel => {
    const filters = map(filterModel, (obj, key) => ({ field: key, term: obj.filter }));
    setFilterBy(filters);
  };

  const updatedProps = {
    columnDefs,
    data: sortedData,
    gsToolKit: true,
    config: {
      enableServerSideFilter: false,
      enableServerSideSorting: true,
      suppressScrollOnNewData: true,
      sortingOrder: ['desc', 'asc'],
      rowClass: 'selectedRow',
    },
    dispatchFilterChange,
    filterBy,
    selectedRows: selectedFundRows,
    dispatchFirstDataRendered,
    selectedRowsComparator,
    noRowDataSelector: noGridRows,
    dispatchSortChange,
    dispatchColumnWidthChanged,
  };

  const handleFundGrpSelectionChange = fundlabel => {
    const updatedFilters = filters.includes(fundlabel) ? filters.filter(data => data !== fundlabel) : [fundlabel, ...filters];
    dispatch({
      type: FILTER_FUND_GROUPS,
      payload: { filters: updatedFilters },
    });
  };

  return (
    <div className='userdetailsfunds__container org_funds'>
      {fundsGroups.length ? (
        <div className='fundGroupsFilter__container'>
          <div className='fundGroupsFilter__header'>{`${t('tkFundGroups')}`}</div>
          <div className='fundGroupsFilter__btns'>
            {fundsGroups.map(fundlabel => (
              <button
                className={cn({ 'fundGroupsFilter__btns-select': filters.includes(fundlabel) })}
                key={fundlabel}
                onClick={() => handleFundGrpSelectionChange(fundlabel)}>
                {fundlabel}
              </button>
            ))}
          </div>
        </div>
      ) : (
        <div />
      )}
      <div className='orgdetailsfunds__gridHeader'>{`${t('tkFundToVisible')} ${organizationName} (${gridData.length})`}</div>
      {withGrid(Grid)(updatedProps)}
    </div>
  );
};

const mapStateToProps = state => ({
  columnDefs: orgFundsTabGridHeader(state, MODULES_KEYS.USER_SEARCH),
});

OrgDetailsGrid.propTypes = {
  columnDefs: PropTypes.array,
};

export default connect(mapStateToProps)(OrgDetailsGrid);
