import { useCallback, useMemo } from 'react';
import { cloneDeep } from 'lodash';
import endPointsMapper from '../../../../../configs/endPointsMapper';
import useUserMaintenance from '../../context';
import useFetch from '../useFetch';
import useLoader from '../useLoader';
import { UPDATE_ACCOUNT_DETAILS } from '../../actions';

const useGetUserDetails = () => {
  const { dispatch: ctxDispatch } = useUserMaintenance();

  const onSuccess = useCallback(response => {
    ctxDispatch({ type: UPDATE_ACCOUNT_DETAILS, payload: { accountDetails: response.accountDetails } });
  }, []);

  const endPointConfig = useMemo(() => cloneDeep(endPointsMapper.FETCH_USER_SEARCH_DETAIL), [endPointsMapper]);

  const { doFetch, loading } = useFetch(endPointConfig, onSuccess);

  useLoader(loading);

  const getUserDetails = useCallback(
    async id => {
      endPointConfig.path = `${endPointsMapper.FETCH_USER_SEARCH_DETAIL.path}/${id}`;
      await doFetch();
    },
    [endPointConfig]
  );

  return { getUserDetails };
};

export default useGetUserDetails;
