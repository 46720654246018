import countBy from 'lodash/countBy';
import { v4 as uuidv4 } from 'uuid'
import AppConstants from '../constants/appConstants';
import {SessionStorage} from './storage';

export const getDomainMapper = () => {
  return document.getElementById('domain') ? document.getElementById('domain').content : 'mosaic-ui';
};

export const isMosaic = () => {
  return getDomainMapper() === 'mosaic-ui';
};

export const getLoginDomainMapper = () => {
  return getDomainMapper() ? getDomainMapper().split('-')[0] : 'mosaic';
};

export const getBrandName = () => (
  getLoginDomainMapper()
);

export const getLoginRoute = (subRoute) => {
  return `${AppConstants.CONTENT_ROUTE}/${subRoute}`;
};

export const getRootRoute = () => {
  return `${AppConstants.CONTENT_ROUTE}/${getDomainMapper()}`;
};

export const getAppRoute = (subRoute) => {
  return `${AppConstants.CONTENT_ROUTE}/${getDomainMapper()}/${subRoute}`;
};

export const getClientMigrationModalSubUrl = () => {
  return `${AppConstants.CLIENT_MIGRATION_INFO_MODAL}${getLoginDomainMapper()}`;
};

export const getCookie = (cname) => {
  const name = `${cname}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export const getValueByPropFromURL = (url, prop) => {
  const parser = document.createElement('a');
  parser.href = url;
  const propVal = parser[prop];
  return (prop === AppConstants.SEARCH) ? propVal.substr(1) : propVal;
};

export const getWindowLocationValueByProp = (prop) => {
  return getValueByPropFromURL(window.location.href, prop);
};

export const getWindowLocationWithoutQueryParams = () => {
  const origin = getWindowLocationValueByProp(AppConstants.ORIGIN);
  let pathname = getWindowLocationValueByProp(AppConstants.PATHNAME);
  pathname = pathname.substr(0, 1) !== '/' ? `/${pathname}` : pathname;
  return `${origin}${pathname}`;
};

export const getQueryParamsMap = () => {
  const qryParamsFromPath = getWindowLocationValueByProp(AppConstants.SEARCH);
  const qryParams = {};
  qryParamsFromPath && qryParamsFromPath.split('&').forEach(paramItem => {
    if (paramItem) {
      const [key, value] = paramItem.split('=');
      if (key && value) {
        qryParams[key] = value;
      }
    }
  });
  return qryParams;
};

export const getQueryParamValue = (paramKey) => {
  return getQueryParamsMap()[paramKey];
};

export const setCookie = (cookieName, cookieValue) => {
  const domain = getWindowLocationValueByProp(AppConstants.HOSTNAME);
  const expiryDate = new Date();
  expiryDate.setMonth(expiryDate.getMonth() + 12);
  document.cookie = `${cookieName}=${cookieValue};expires=${expiryDate};domain=${domain};path=/`;
};

export const deleteCookie = (name) => {
  const domain = getWindowLocationValueByProp(AppConstants.HOSTNAME);
  document.cookie = `${name}=; Path=/; domain=${domain}; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};

export const isInterstitialPage = () => {
  const pathName = getWindowLocationValueByProp(AppConstants.PATHNAME);
  return (pathName === getLoginRoute(getClientMigrationModalSubUrl()));
};

export const isLogoutRoute = () => {
  const pathName = getWindowLocationValueByProp(AppConstants.PATHNAME);
  return (pathName === getLoginRoute(AppConstants.LOGOUT_ROUTE));
};

export const getTokenValue = (name, stringLookup) => {
  const regex = new RegExp(`[&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(`&${stringLookup}`);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const postToNewPage = ({url, params, method = 'POST'}) => {
  method = method || 'post';

  const form = document.createElement('form');
  form.setAttribute('method', method);
  form.setAttribute('action', url);

  const paramsArray = Object.keys(params);
  paramsArray.forEach((val) => {
    if (Object.prototype.hasOwnProperty.call(params, val) && val !== 'target') {
      const attributeKey = (val === 'token') ? 'not' : val;
      const hiddenField = document.createElement('input');
      hiddenField.setAttribute('type', 'hidden');
      hiddenField.setAttribute('name', attributeKey);
      hiddenField.setAttribute('value', params[val]);
      form.appendChild(hiddenField);
    }
  });
  document.body.appendChild(form);
  form.submit();
};

export const getElementValueFromHtmlContent = (htmlContent, key) => {
  try {
    if (htmlContent.includes('html')) {
      const parser = new DOMParser();
      const htmlDoc = parser.parseFromString(htmlContent, 'text/html');
      const htmlElement = htmlDoc.getElementsByName(key);
      return htmlElement && htmlElement.length > 0 ? htmlElement[0].getAttribute('value') : null;
    }
  } catch (err) {
    console.log(`Error occurred => ${err}`);
  }
  return null;
};

export const isUserLandedFromMosaicPage = () => {
  return document.referrer.includes(getRootRoute());
};

export const hasFoundSequentialCharacter = (s) => {
  if (s.length >= 3) {
    s = s.toLowerCase();
    for (let i = 0; i < s.length; i++)
      if (+s[+i + 1] === +s[i] + 1 && +s[+i + 2]
        === +s[i] + 2 && +s[+i + 3] === +s[i] + 3) return true;
    for (let i = 0; i < s.length; i++)
      if (String.fromCharCode(s.charCodeAt(i) + 1) === s[+i + 1] &&
        String.fromCharCode(s.charCodeAt(i) + 2) === s[+i + 2] &&
        String.fromCharCode(s.charCodeAt(i) + 3) === s[+i + 3]) return true;
  }
  return false;
};

export const hasFoundRepeatingCharacter = (val, repeatCount) => {
  if (val) {
    return (val.match(`(.)\\1{${repeatCount},}`) || !!val.split('').find(letter => countBy(val)[letter] > repeatCount));
  }
  return false;
};

export const getTargetUrl = () => {
  const targetURL = getCookie(AppConstants.STATE_COOKIE);
  if (targetURL) {
    const targetPath = getValueByPropFromURL(decodeURIComponent(targetURL), AppConstants.PATHNAME);
    if (targetPath !== getLoginRoute(AppConstants.LOGIN_ROUTE)) {
      return targetPath;
    }
  }
  return getAppRoute(AppConstants.PORTFOLIO_ROUTE);
};


export const getNestedObject = (nestedObj, pathArr) => {
  return pathArr.reduce((obj, key) =>
    (obj && obj[key] !== 'undefined') && obj[key], nestedObj);
};

export const getZendeskURL = () => {
  return getAppRoute(AppConstants.FAQ_ROUTE);
};

export const getUpdatedGlossaryText = ({glossaryTxt = ''}) => {
  return glossaryTxt.replace('[PATH]', getAppRoute(AppConstants.GLOSSARY_ROUTE));
};

export const captureAffiliatedSiteQueryParams = () => {
  const analyticsKeys = ['ch', 'sch', 'pos', 'lt'];
  const queryParamsMap = getQueryParamsMap();
  analyticsKeys.forEach(itemKey => {
    const qryParamVal = queryParamsMap[itemKey];
    if (qryParamVal) {
      setCookie(`query_${itemKey}`, qryParamVal);
    }
  });
};

export const hasAffiliateSiteAccessedMosaic = () => {
  const queryParamsMap = getQueryParamsMap();
  return queryParamsMap && queryParamsMap.ch === 'affiliate';
};

export const handleQueryParams = () => {
  const isQryParamFound = !!getWindowLocationValueByProp(AppConstants.SEARCH);
  if (isQryParamFound) {
    const isShowTradeBlotter = getQueryParamValue(AppConstants.SHOW_TRADE_BLOTTER);
    // To show the Trade Blotter on Screen Load
    if (isShowTradeBlotter) {
      SessionStorage.setItem(AppConstants.IS_MODAL_OPEN, true);
    }

    // To capture Affiliated Site Query Params
    captureAffiliatedSiteQueryParams();
  }
};

export const getUUID = () => {
  return uuidv4();
};

export const getRandomIdForThreatMetrix = () => {
  const separator = '|';
  const randomUUID = getUUID();
  const now = (new Date()).getTime();
  const rawConnectionId = `${randomUUID}${separator}${now}`;
  return btoa(rawConnectionId).replace('=', '');
};

export const convert2PascalCase =(string) => {
  return string ? string.replace(/(\w)(\w*)/g,
    (g0, g1, g2) => {return g1.toUpperCase() + g2.toLowerCase();}) : '';
};

export const isRSATMXOrTMXProfiling = (riskEngine) => {
  return ['RSA_TMX', 'TMX'].includes(riskEngine);
};

export const convertTime12to24 = (time12h) => {
  const splitedTime = time12h.match(/(\d?\d:\d\d)\s*(\w{2})/i);
  const time = splitedTime[1];
  const modifier = splitedTime[2];
  const hrAndMin = time.split(':');
  let hours = hrAndMin[0];
  const minutes = hrAndMin[1];
  if (hours === '12') {
    hours = '00';
  }

  if (modifier === 'PM') {
    hours = parseInt(hours, 10) + 12;
  }

  return `${hours}:${minutes}`;
};

const getTimeList = () => {
  const x = 15; // minutes interval
  const times = []; // time array
  let tt = 0; // start time
  const ap = ['PM', 'AM']; // AM-PM

  // loop to increment the time and push results in array
  for (let i=0;tt<24*60; i++) {
    const hh = Math.floor(tt/60); // getting hours of day in 0-24 format
    const mm = (tt%60); // getting minutes of the hour in 0-55 format
    let label = '';
    const apTime = ap[Math.floor(hh/12)];
    if(hh === 0){
      label = `12:${(`0${  mm}`).slice(-2)}`;
    } else if(hh === 12){
      label = `12:${(`0${  mm}`).slice(-2)}`;
    } else {
      label = `${(`0${  hh % 12}`).slice(-2)  }:${  (`0${  mm}`).slice(-2)  }`;
    }
    times[i] = { label: `${label} ${apTime}`, value: convertTime12to24(`${label} ${apTime}`)};
    tt += x;
  }
  return times;
};

export const timeIntervalList = getTimeList();

export const swap = (arr) => {
  [arr[0], arr[1]] = [arr[1], arr[0]];
  return arr;
};

export const updateCurrentYear = (val) => (
  val.replace(/\[YEAR\]/, (new Date()).getFullYear().toString())
);

export const removeUnwantedTags = (contents = '') => (
  contents
    .replace(/<p>/g, '')
    .replace(/<\/p>/g, '')
    .replace(/\n/g, '')
    .replace(/<br clear="none">/g, '')
    .trim()
);

export const getKeyByValue = (obj, filterValue) => {
  const filteredEntryArr = Object.entries(obj)
    .filter(([, value]) => {
      return (value === filterValue);
    });
  const [key] = Object.keys(Object.fromEntries(filteredEntryArr));
  return key;
};

export const flattenData = (data, dataKey, nestedKey, newArr = []) => {
  for (let i = 0; i < data.length; i++) {
    const singleData = data[i];
    if (singleData[nestedKey]) {
        newArr = flattenData(singleData[nestedKey], dataKey, nestedKey, newArr);
        newArr = [...newArr, ...singleData[dataKey]];
    } else {
        newArr = [...newArr, singleData];
    }
  }
  return newArr;
};
