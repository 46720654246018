import React, { useState } from 'react';
import {connect} from 'react-redux';
import get from 'lodash/get';
import map from 'lodash/map';

import Grid from '../../../components/core/Grid';
import {
  getGridData, gridHeader, getFilterVisibility, isLoading, getLastEvent, getActiveViewName,
  getManagerNames, currentViewSelector, columnsMetadata, preDefinedSortingState, noRowDataSelector,
  columnSortingStateMappedData, filterByMappedData, startExcelExport, isOpenAccountsOnly, isInstitutionalShareClassOnly,
  getFilterModel, getFilters, selectedRows, snackbarList
} from '../../../selectors/pages/fundFinder';

import  mapDispatchToProps from './actionsHandler';
import withGrid  from '../../../components/hoc/withGrid';
import { excelExportFooterConfiguration } from '../../../configs/excelExport/exportFooter';
import {COLUMN_COLID} from '../../../constants/pageConstants';
import { selectedRowsComparatorFund } from '../../../helpers/gridFund';
import {reportDisclaimerSelector, labelSelector, selectedFirms} from '../../../selectors/app';
import {userSelector} from '../../../selectors/user';
import {gridCompositeFormatter} from '../../../utils/portfolioGridFormatter';
import { getExportColumns, getFormattedFilename } from '../../../modules/Grid/helper';

const processCellCallback  = ({value, node: {data}, column: {colId, colDef} = {}}) => {
  switch (colId) {
    case COLUMN_COLID.NAV_SCHEDULE :
    case COLUMN_COLID.NAV_AS_OF_TIME :
    case COLUMN_COLID.INCEPTION_DATE :
    case COLUMN_COLID.PERFORMANCE_AS_OF:
    case COLUMN_COLID.ASSETS_AS_OF:
    case COLUMN_COLID.DURATION_AS_OF:
    case COLUMN_COLID.LIQUIDITY_ASSETS_AS_OF:
      return (value && value.length) ? gridCompositeFormatter({data, colDef, value}) : value;
    case COLUMN_COLID.ADJUSTED_ONE_DAY_YIELD:
    case COLUMN_COLID.ADJUSTED_SEVEN_DAY_CURR_YIELD:
    case COLUMN_COLID.ADJUSTED_SEVEN_DAY_EFF_YIELD:
    case COLUMN_COLID.ADJUSTED_THIRTY_DAY_YIELD:
      return gridCompositeFormatter({data, colDef, value});
    default :
      return value;
  }
};

export const FundFinderGrid  = (props) => {

  const config = {
    enableRowGroup: false,
    enableServerSideSorting: true,
    suppressScrollOnNewData: true,
    groupSuppressAutoColumn: true,
    enableServerSideFilter : false,
    context: {  // custom prop
      suppressGroupCheckbox: true
    },
    sortingOrder: ['desc', 'asc'],
    floatingFiltersHeight: 0
  };
  const components = {
    linkRenderer: ((params) => {
      const cellLabel = (params.hasOwnProperty('value')
        && (params.value !== undefined
          && params.value !== null
          && params.value !== '')) ? params.value : '--';
      return <a href='javascript:void(0);'>{cellLabel}</a>
    })
  };

  // TODO: consolidate export configs for screens
  const getExcelExportConfig = () => {
    const {
      currentView,
      disclaimer,
      userInformation,
      filterByMappedData,
      openAccountsOnly,
      institutionalShareClassOnly,
      columnSortingStateMappedData,
      selectedFirms,
      includeZeroBalancePositions,
      labels,
      selectedManagers,
    } = props;

    const sheetName = labels.tkFundFinder;
    const currentViewName = get(currentView, 'name', '');
    const viewLabel = currentViewName.split(':')[0].trim().replace(/ /g, '_');

    const fileName = getFormattedFilename(viewLabel);
    const excelStyles = [{ id: 'align-left', alignment: { horizontal: 'left' } }];

    const footer = excelExportFooterConfiguration({
      disclaimer,
      userInformation,
      filterByMappedData,
      openAccountsOnly,
      institutionalShareClassOnly,
      columnSortingStateMappedData,
      selectedFirms,
      includeZeroBalancePositions,
      labels,
      selectedManagers,
    });

    return {
      footer,
      fileName,
      sheetName,
      skipGroups: true,
      exportColumnsFunc: getExportColumns,
      processCellCallback,
      excelStyles,
    };
  };

  const [startTime, setStartTime] = useState(null);
  const dispatchLoadStart = () => {
    setStartTime(new Date().getTime());
  };

  const dispatchLoadEnd = () => {
    const {lastEvent, data, columnDefs, selectedView, selectedManagers, snackbarList} = props;

    lastEvent && props.dispatchLoadEnd(
      lastEvent,
      data.length,
      columnDefs,
      selectedView,
      selectedManagers.replace(/,/g, '|'),
      startTime,
      snackbarList
    );
  };

  const onFilterApplied = (filterBy, totalFilteredRows) => {
    const {columnDefs, currentView, selectedManagers} = props;
    const filters = map(filterBy, (obj, key) => ({field: key, term: obj.filter}));
    props.dispatchFilterApplied(
      filters,
      totalFilteredRows,
      columnDefs.map((item) => item.headerName).join('|'),
      currentView.name,
      selectedManagers.replace(/,/g, '|')
    );
  };

  const updatedProps = {
    ...props,
    components,
    config,
    dispatchLoadStart,
    dispatchLoadEnd,
    excelExportConfig: getExcelExportConfig(),
    selectedRowsComparator: selectedRowsComparatorFund,
    dispatchFilterApplied: onFilterApplied,
  };

  return withGrid(Grid)(updatedProps);
};

export const mapStateToProps = (state) => ({
  columnDefs: gridHeader(state),
  data: getGridData(state),
  filterVisibility: getFilterVisibility(state),
  filterModel : getFilterModel(state),
  filterBy: getFilters(state),
  loading: isLoading(state),
  lastEvent: getLastEvent(state),
  currentView: currentViewSelector(state),
  selectedView: getActiveViewName(state),
  selectedRows: selectedRows(state),
  selectedManagers: getManagerNames(state),
  columnsMetadata: columnsMetadata(state),
  preDefinedSortingState: preDefinedSortingState(state),
  noRowDataSelector: noRowDataSelector(state),
  noRowMsg: noRowDataSelector(state),
  disclaimer: reportDisclaimerSelector(state),
  userInformation: userSelector(state),
  filterByMappedData : filterByMappedData(state),
  columnSortingStateMappedData : columnSortingStateMappedData(state),
  selectedFirms: selectedFirms(state),
  labels: labelSelector(state),
  startExcelExport: startExcelExport(state),
  enableAddTradeToBlotter: true,
  institutionalShareClassOnly : isInstitutionalShareClassOnly(state),
  openAccountsOnly: isOpenAccountsOnly(state),
  snackbarList: snackbarList(state)
});

export default  connect(mapStateToProps, mapDispatchToProps)(FundFinderGrid);
