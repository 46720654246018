import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CustomView from '../../../../../components/app/CustomView';
import { MODULES_KEYS } from '../../../../../constants/pageConstants';
import { getErrorObject, checkIsPageInErrorMode, duplicateViewName } from '../../../../../selectors/pages/portfolio';
import { selectedModelView, groupedColumnsSelector } from '../../selectors';
import { allViewsSelector, columnsMetadata, openCustomViewModal, modelActiveMode, modalLoading } from '../../../../../modules/Grid/selector';
import { gridCloseCustomViewModal, gridSaveCustomView, gridEditCustomView, gridDeleteCustomView } from '../../../../../modules/Grid/actionsCreator';
import { getSaveViewAnalyticsData } from '../../../../../modules/Grid/analytics';
import { eventAnalytics } from '../../../../../actions/sagas';
import { addPageContext } from '../../../../../actions/page';
import { labelSelector, getLabel } from '../../../../../selectors/app/index';
import Modal from '../../../../../components/core/Modal';

export const UserSearchCustomViewModel = props => {
  const handleClose = () => {
    props.addPageContext({ isPageInErrorMode: false, duplicateViewName: undefined });
    props.closeModal();
  };

  const dispatchViewSaveAnalytics = (columns, view) => {
    const cols = columns.map(data => ({ headerName: props.labels[data.headerName] }));
    props.dispatchViewSaveAnalytics(cols, view);
  };

  const modalProps = {
    animation: true,
    suppressScrollOnActive: true,
    customModalStyle: { overflow: 'hidden' },
    open: props.open,
    handleClose,
  };

  const newProps = { ...props, dispatchViewSaveAnalytics };

  return (
    <Modal {...modalProps}>
      <CustomView {...newProps} />
    </Modal>
  );
};

const mapStateToProps = state => ({
  columnsMetadata: columnsMetadata(state, MODULES_KEYS.USER_SEARCH),
  groupedColumns: groupedColumnsSelector(state, MODULES_KEYS.USER_SEARCH),
  allViews: allViewsSelector(state, MODULES_KEYS.USER_SEARCH),
  open: openCustomViewModal(state, MODULES_KEYS.USER_SEARCH),
  currentView: selectedModelView(state, MODULES_KEYS.USER_SEARCH),
  modelActiveMode: modelActiveMode(state, MODULES_KEYS.USER_SEARCH),
  isLoading: modalLoading(state, MODULES_KEYS.USER_SEARCH),
  duplicateViewName: duplicateViewName(state),
  errorObject: getErrorObject(state),
  isPageInErrorMode: checkIsPageInErrorMode(state),
  labels: labelSelector(state),
  label: key => getLabel(state, key),
});

export const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(gridCloseCustomViewModal(MODULES_KEYS.USER_SEARCH)()),
  saveCustomView: data => dispatch(gridSaveCustomView(MODULES_KEYS.USER_SEARCH)(data)),
  editCustomView: data => dispatch(gridEditCustomView(MODULES_KEYS.USER_SEARCH)(data)),
  deleteCustomView: data => dispatch(gridDeleteCustomView(MODULES_KEYS.USER_SEARCH)(data)),
  addPageContext: obj => dispatch(addPageContext(obj)),
  doAnalytics: data => dispatch(eventAnalytics(data)),
  dispatchViewSaveAnalytics: (viewColumns, view) => {
    const data = getSaveViewAnalyticsData(viewColumns, view);
    dispatch(eventAnalytics(data));
  },
});

UserSearchCustomViewModel.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.func,
  addPageContext: PropTypes.func,
  errorObject: PropTypes.object,
  dispatchViewSaveAnalytics: PropTypes.func,
  currentView: PropTypes.string,
  labels: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserSearchCustomViewModel);
