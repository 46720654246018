import uniqBy from 'lodash/uniqBy';
import _ from 'lodash';
import { GROUP_NO_LABEL, SORT, GROUP_LABEL_KEY, COLUMN_COLID, SORT_DATE_TYPE } from '../constants/pageConstants';
import Constants from '../constants/appConstants';
import {toDate} from './dateFormatter';
const { PORTFOLIO_DATA_UNIQUE_KEY, FUND_FINDER_DATA_UNIQUE_KEY, FUND_FINDER_MULTISORT_COLUMN1,
  FUND_FINDER_MULTISORT_COLUMN2 } = Constants;

export const getSortedNumberData = (dataRows, key, isAsc) => {
  dataRows.sort((a, b) => {
    const A =  Number(a[key]);
    const B =  Number(b[key]);
    if (A < B) return isAsc ? -1 : 1;
    if (A > B) return isAsc ? 1 : -1;
    return 0;
  });
  return dataRows;
};

export const getSortedStringData = (dataRows, key, isAsc) => {
  dataRows.sort((a, b) => {
    const A =  a[key].toLowerCase();
    const B =  b[key].toLowerCase();
    if (A < B) return isAsc ? -1 : 1;
    if (A > B) return isAsc ? 1 : -1;
    return 0;
  });
  return dataRows;
};

export const getSortedDates = (dataRows, key, isAsc, splitBy) => {
  dataRows.sort((a, b) => {
    const A = toDate(a[key], splitBy);
    const B = toDate(b[key], splitBy);
    if (A < B) return isAsc ? -1 : 1;
    if (A > B) return isAsc ? 1 : -1;
    return 0;
  });
  return dataRows;
};

export const getSortedAmountData = (dataRows, key, isAsc) => {
  dataRows.sort((a, b) => {
    if(a[key] && b[key]) {
      const A = Number(a[key].replace(/,/g, ''));
      const B = Number(b[key].replace(/,/g, ''));
      if (A < B) return isAsc ? -1 : 1;
      if (A > B) return isAsc ? 1 : -1;
      return 0;
    }
    return 0;
  });
  return dataRows;
};


export const getSortedCheckboxData = (array=[], key, isAsc, selectedRows=[], uniqueKey) => {
  const selectedRow = [];
  const notSelectedRow = [];
  const disabledRow = [];
  array.forEach(data => {
    if(data[key] === false){
      disabledRow.push(data);
    } else if(!selectedRows.find((row) => data[uniqueKey] === row.id)){
      notSelectedRow.push(data);
    } else {
      selectedRow.push(data);
    }
  });
  return isAsc ? [...notSelectedRow, ...selectedRow, ...disabledRow] : [...selectedRow, ...notSelectedRow, ...disabledRow];
};

export const getSortedDateData = (dataRows, key, isAsc) => {
  const AM = [];
  const PM = [];

  dataRows.forEach(data => {
    const data1  = data[key].split(' ')[1];
    if( data1 === SORT_DATE_TYPE.AM){
      AM.push(data);
    } else PM.push(data);
  });

  const getDateData = data => {
    data.sort((a, b) => {
      let A =  a[key];
      let B =  b[key];
      const a1 = A.split(':')[0];
      const b1 = B.split(':')[0];
      if(a1.length === 1){
        A = A.replace(a1, `0${a1}`);
      } else if(a1 === '12'){
        A = A.replace('12', '00');
      }

      if(b1.length === 1){
        B = B.replace(b1, `0${b1}`);
      } else if(b1 === '12'){
        B = B.replace('12', '00');
      }

      if (A < B) return isAsc ? -1 : 1;
      if (A > B) return isAsc ? 1 : -1;
      return 0;
    });
    return data;
  };
  return isAsc ? [ ...getDateData(AM), ...getDateData(PM)] : [ ...getDateData(PM), ...getDateData(AM)];
};

export const getSortedData = (array=[], key, sortType, dataType, selectedRows, uniqueKey) => {
  if(!array.length || !key || !sortType) return array;
  const dataRows = [];
  const noDataRows = [];
  array.forEach(row => {
    // Flatten rebate details
    if(
      (
        key === COLUMN_COLID.ADJUSTED_ONE_DAY_YIELD ||
        key === COLUMN_COLID.ADJUSTED_SEVEN_DAY_CURR_YIELD ||
        key === COLUMN_COLID.ADJUSTED_SEVEN_DAY_EFF_YIELD ||
        key === COLUMN_COLID.ADJUSTED_THIRTY_DAY_YIELD
      ) && row.rebateDetails
    ) {
      row[key] = row.rebateDetails[key];
    }
    if(Object.prototype.hasOwnProperty.call(row, key) && !_.isUndefined(row[key]) && row[key] !== '' &&
      row[key] !== null && !GROUP_NO_LABEL.includes(row[key])) {
      dataRows.push(row);
    } else {
      noDataRows.push(row);
    }
  });

  const rowData = [];
  const isAsc = sortType === SORT.ASC;
  switch (dataType) {
    case SORT_DATE_TYPE.number:
      rowData.push(...getSortedNumberData(dataRows, key, isAsc));
      break;
    case SORT_DATE_TYPE.string:
      rowData.push(...getSortedStringData(dataRows, key, isAsc));
      break;
    case SORT_DATE_TYPE.date:
      rowData.push(...getSortedDateData(dataRows, key, isAsc));
      break;
    case SORT_DATE_TYPE.checkbox:
      rowData.push(...getSortedCheckboxData(dataRows, key, isAsc, selectedRows, uniqueKey));
      break;
    default:
      rowData.push(...getSortedStringData(dataRows, key, isAsc));
  }
  rowData.push(...noDataRows);
  return rowData;
};

export const getGroupedSortedData = (array, columnState) => {
  const { sort, dataType } = columnState;
  const portfolioRowData = getSortedData(array, GROUP_LABEL_KEY, sort, dataType);
  return portfolioRowData.map((group) => {
    if (group.children && group.children.length > 0) {
      group.children = getSortedData(group.children, GROUP_LABEL_KEY, sort, dataType);
      return group;
    } else {
      return group;
    }
  });
};

// Main Util for grid data sorting.
export const sortGridData = (gridData=[], columnState=[], selectedRows=[], skipGroupSorting = false) => {
  if(!gridData.length) return [];
  const filteredColumnState = columnState.filter(column => column.colId);
  const sortMetaData = uniqBy(filteredColumnState, 'colId');
  const groupColumn = sortMetaData.find((column) => column.colId === COLUMN_COLID.GROUP);
  if(groupColumn && !skipGroupSorting){
    gridData = getGroupedSortedData(gridData, groupColumn);
  }
  const nonGroupColumn = sortMetaData.filter(sortData => sortData.colId !== COLUMN_COLID.GROUP) || [];
  if(!nonGroupColumn.length) return gridData;
  const { colId, sort, dataType } = nonGroupColumn[0];
  const uniqueKey = PORTFOLIO_DATA_UNIQUE_KEY;
  return gridData.map((group) => {
    if(group.children && group.children.length){
      group.children.forEach((data) => {
        data.details = getSortedData(data.details, colId, sort, dataType, selectedRows, uniqueKey);
      });
      return group;
    } else {
      group.details = getSortedData(group.details, colId, sort, dataType, selectedRows, uniqueKey);
      return group;
    }
  });
};

export const sortReportGridData = (gridData=[], columnState=[], selectedRows=[], skipGroupSorting = false) => {
  if(!gridData.length) return [];
  const filteredColumnState = columnState.filter(column => column.colId);
  const sortMetaData = uniqBy(filteredColumnState, 'colId');
  const groupColumn = sortMetaData.find((column) => column.colId === COLUMN_COLID.GROUP);
  if(groupColumn && !skipGroupSorting){
    gridData = getGroupedSortedData(gridData, groupColumn);
  }
  const nonGroupColumn = sortMetaData.filter(sortData =>
    sortData.colId !== COLUMN_COLID.GROUP
  ) || [];
  if(!nonGroupColumn.length) return gridData;
  const { colId, sort, dataType, type } = nonGroupColumn[0];
  const uniqueKey = PORTFOLIO_DATA_UNIQUE_KEY;
  const sortDataType = (['variableNumericColumn', 'numericSortColumn', 'navColumn'].includes(type)) ? 'number' : dataType;
  return getSortedData(gridData, colId, sort, sortDataType, selectedRows, uniqueKey);
};

export const sortFundFinderGridData = (gridData=[], columnState=[], selectedRows=[]) => {
  if(!gridData.length) return [];
  const uniqueKey = FUND_FINDER_DATA_UNIQUE_KEY;
  const column = columnState.filter(column => column.colId);
  const sortMetaData = uniqBy(column, 'colId');
  if(!sortMetaData.length) return gridData;
  const { colId, sort, dataType } = column[0];
  let data = gridData;
  if(colId === FUND_FINDER_MULTISORT_COLUMN1){
    data = getSortedData(gridData, FUND_FINDER_MULTISORT_COLUMN2, sort, dataType, selectedRows, uniqueKey);
  }
  return getSortedData(data, colId, sort, dataType, selectedRows, uniqueKey);
};

export const getFundFinderSortedData = (gridData = [], params = {}) => {
  if(!gridData.length) return gridData;
  const { sortedMeta, selectedRows } = params;
  return sortFundFinderGridData(gridData, sortedMeta, selectedRows);
};

export const sortWithFlatDataWithGrouping = (
  gridData=[], columnState=[],
  selectedRows=[], groupBy = 'none'
) => {
  if(!gridData.length) return [];
  const filteredColumnState = columnState.filter(column => column.colId);
  const sortMetaData = uniqBy(filteredColumnState, 'colId');
  const groupColumn = sortMetaData.find((column) => column.colId === COLUMN_COLID.GROUP);
  let nonGroupColumn = sortMetaData.find(sortData => sortData.colId !== COLUMN_COLID.GROUP);
  const dataRows = [];
  const noDataRows = [];
  const sortedData = [];
  const key = (groupBy !== 'none' && groupBy !== '') ? groupBy : nonGroupColumn.field;
  gridData.forEach(row => {
    if(row && (row[key] || row[key] === 0)){
      dataRows.push(row);
    } else noDataRows.push(row);
  });
  if(groupBy !== 'none' && groupBy !== '' && groupColumn){
    const groupedData = _.groupBy(dataRows, groupBy);
    const isDesc = groupColumn.sort === SORT.DESC;
    const temp = Object.keys(groupedData);
    const newKeys = [];
    const emptyKeys = [];
    temp.forEach(key => {
      if (key === '' || _.isUndefined(key) || key === null) {
        emptyKeys.push(key);
      } else {
        newKeys.push(key);
      }
    });
    newKeys.sort((x, y) => {
      if(x === y) return 0;
      else if(x > y) return isDesc ? -1 : 1;
      return isDesc ? 1 : -1;
    });
    const keys = [...newKeys, ...emptyKeys];
    if(nonGroupColumn){
      if(nonGroupColumn.proxyColumn){
        const temp = nonGroupColumn.proxyMapping.find(data => data.field === nonGroupColumn.field);
        nonGroupColumn = { ...nonGroupColumn, dataType: temp.dataType };
      }
      const { sort, dataType, field } = nonGroupColumn;
      keys.forEach(key => {
        sortedData.push(...getSortedData(groupedData[key], field, sort, dataType, selectedRows));
      });
    } else {
      keys.forEach(key => {
        sortedData.push(...groupedData[key]);
      });
    }
  } else {
    const { sort, dataType, field } = nonGroupColumn;
    sortedData.push(...getSortedData(dataRows, field, sort, dataType, selectedRows));
  }
  sortedData.push(...noDataRows);
  return sortedData;
};
