/*
export const propsToBeDeleted = () => {
  return ['rowData', 'columnDefs', 'gridId', 'gridEvents'];
};
*/
// TODO : Confirm if we need this else remove

import { memoize } from 'lodash';

export const defaultContainerStyle = {'height': '100%', 'minWidth':700};

export const getStartingGroupByRowIndex = memoize((groupByColumns = [], groupByArr) => {
  let rowIndex = -1;
  if (! groupByArr || !groupByColumns.length) return rowIndex;

  groupByArr.every((column) => {
    groupByColumns.every((item, index) => {
      const {colId} = item;
      if (colId === column) {
        rowIndex = index;
        return false
      }
      return true
    });
    if (rowIndex !== -1) {
      return false
    }
    return true;
  });

  return rowIndex
}, (...args) => {
  const groupByColumns = args[0];
  const groupArr = args[1];

  return `${groupByColumns.map(item => item.colId).join("")}-${groupArr.join("")}`;
})
