import React from 'react';
import OrgDetailsGrid from './OrgDetailsGrid';
import translator from '../../../../../../services/translator';

const { translate: t } = translator;

const Funds = () => (
  <div className='userdetailsfunds orgdetailsfunds gs-scrollbar'>
    <div className='orgdetailsfunds__header'>{t('tkFundsEntitlements')}</div>
    <div className='orgdetailsfunds__subheader'>{t('tkOrgFundsSubtitle')}</div>
    <OrgDetailsGrid />
  </div>
);

export default Funds;
