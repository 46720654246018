import cloneDeep from 'lodash/cloneDeep';
import findIndex from 'lodash/findIndex';
import {getProxyColumn} from '../../utils/reportingUtils';
import {COLUMN_COLID} from '../../constants/pageConstants';

export const STANDARD_REPORT_VIEW_NAMES = {
  'account-balance': 'ACCOUNTBALANCEREPORT',
  'historical-transactions': 'HISTORICALTRANSACTIONS',
  'class-fees': 'CLASSFEEREPORT',
  'monthly-dividend': 'MONTHLYDIVIDENDACTIVITY',
  'monthly-service': 'MONTHLYSERVICEFEES',
  'debit-credit-accounts': 'DEBITCREDITACCOUNTLISTINGREPORT',
  'gain-loss-onshore': 'GAINLOSS',
  'ex-post-costs': 'EXPOSTCOSTSANDCHARGESSUMMARY',
  'current-trade-status': 'CURRENTTRADESTATUS',
  'current-trade-totals': 'CURRENTTRADETOTALSBYFUND',
  'wire-settlement': 'WIRESETTLEMENT',
  'trade-approval-exceptions': 'HISTORICALTRADEAPPROVALEXCEPTION',
  'debit-credit-transactions': 'DEBITCREDITDAILYTRANSACTIONACTIVITY',
  'debit-credit-settlement': 'DEBITCREDITSETTLEMENTSUMMARY',
  'debit-credit-activity': 'DEBITCREDITDAILYFEEDSUMMARY',
  'fund-balances': 'FUNDBALANCEREPORT',
  'fund-rates-factors': 'RATESANDFACTORS'
};

export const REPORTS_CONFIG = {
  'ACCOUNTBALANCEREPORT': {
    isReportHasVariableColumns: true,
    pageId: 'account-balance'
  },
  'HISTORICALTRANSACTIONS': {
    isReportHasVariableColumns: false,
    pageId: 'historical-transactions'
  },
  'CLASSFEEREPORT': {
    isReportHasVariableColumns: false,
    pageId: 'class-fees'
  },
  'MONTHLYDIVIDENDACTIVITY': {
    isReportHasVariableColumns: false,
    pageId: 'monthly-dividend'
  },
  'MONTHLYSERVICEFEES': {
    isReportHasVariableColumns: false,
    pageId: 'monthly-service'
  },
  'DEBITCREDITACCOUNTLISTINGREPORT': {
    isReportHasVariableColumns: false,
    pageId: 'debit-credit-accounts'
  },
  'GAINLOSS': {
    isReportHasVariableColumns: false,
    pageId: 'gain-loss-onshore'
  },
  'EXPOSTCOSTSANDCHARGESSUMMARY': {
    isReportHasVariableColumns: false,
    pageId: 'ex-post-costs'
  },
  'CURRENTTRADESTATUS': {
    isReportHasVariableColumns: false,
    pageId: 'current-trade-status'
  },
  'CURRENTTRADETOTALSBYFUND': {
    isReportHasVariableColumns: false,
    pageId: 'current-trade-totals'
  },
  'WIRESETTLEMENT': {
    isReportHasVariableColumns: false,
    pageId: 'wire-settlement'
  },
  'HISTORICALTRADEAPPROVALEXCEPTION': {
    isReportHasVariableColumns: false,
    pageId: 'trade-approval-exceptions'
  },
  'DEBITCREDITDAILYTRANSACTIONACTIVITY': {
    isReportHasVariableColumns: false,
    pageId: 'debit-credit-transactions'
  },
  'DEBITCREDITSETTLEMENTSUMMARY': {
    isReportHasVariableColumns: false,
    pageId: 'debit-credit-settlement'
  },
  'DEBITCREDITDAILYFEEDSUMMARY': {
    isReportHasVariableColumns: false,
    pageId: 'debit-credit-activity'
  },
  'FUNDBALANCEREPORT': {
    isReportHasVariableColumns: true,
    pageId: 'fund-balances'
  },
  'RATESANDFACTORS': {
    isReportHasVariableColumns: false,
    pageId: 'fund-rates-factors',
    showUnfundedShareclasses: true
  }
};

export const getUpdatedView = (currentActiveView, allViews) => {
  const activeView = cloneDeep({...currentActiveView, isActive: true});
  activeView && activeView.columns.forEach((column, i) => {
    if (column.sort !== 'asc' && column.sort !== 'desc') {
      column.sort = 'none';
    }
    column.columnIndex = i;
  });
  const views = cloneDeep(allViews);
  const index = findIndex(views, (view) => view.id === activeView.id);
  views[index] = {...activeView};
  return {
    views,
    groupBy: activeView.groupBy
  };
};

export const onEditView = (currentActiveView, allViews, addEditDeleteViewData) => {
  const {views, groupBy} = getUpdatedView(currentActiveView, allViews);
  addEditDeleteViewData({
    views,
    groupBy,
    isOpenCustomViewModal: false,
    isModalLoading: false
  });
};

export const hasViewColumnsChanged = (currentViewColumns, prevViewColumns) => {
  if (!currentViewColumns || !prevViewColumns) return false;
  if (currentViewColumns.length > prevViewColumns.length) return true;

  const prevColumnMap = prevViewColumns.reduce((map, column) => {
    const { field } = column;
    map[field] = true;
    return map;
  }, {});

  let hasColumnsChange = false;
  currentViewColumns.every((column) => {
    const {field} = column;
    if (!prevColumnMap[field]) {
      hasColumnsChange = true;
      return false;
    }
    return true;
  });

  return hasColumnsChange;
};


export const getUpdatedColumns = (columns) => {
  const [changedColumn] = columns;
  const [colId] = changedColumn.colId.split('_');
  return [{...changedColumn, colId}];
};

export const getSortParams = (colId, order, props) => {
  if (!colId) return;
  const {
    currentView, columnsMetadataWithKey, preDefinedSortingState, selectedRows,
    groupByField, preferredAccountIdentifier, preferredFundIdentifier
  } = props;
  const metaDataCol = columnsMetadataWithKey[colId];
  const sortedColumn = getProxyColumn(
    metaDataCol, preferredAccountIdentifier,
    preferredFundIdentifier, columnsMetadataWithKey
  );
  const {field: updatedColId} = sortedColumn;
  const activeView = cloneDeep(currentView);
  activeView && activeView.columns.forEach(column => {
    if (column.colId !== COLUMN_COLID.GROUP) {
      column.sort = 'none';
    }
  });
  const colIdIndex = activeView.columns.findIndex(column => column.colId === colId);
  activeView.columns[colIdIndex].sort = order;
  const sortedColMetaData = columnsMetadataWithKey[updatedColId];
  const sortedMeta = [{...sortedColMetaData, sort: order}];
  if (updatedColId !== COLUMN_COLID.GROUP && groupByField !== '') {
    const data = preDefinedSortingState.find(sortedMetaData => sortedMetaData.colId === COLUMN_COLID.GROUP);
    if (data) {
      sortedMeta.push(data);
    }
  }
  const params = {sortedMeta, selectedRows, skipGroupSorting: !(updatedColId === COLUMN_COLID.GROUP)};
  return {activeView, params};
};
