import {isEmpty, isEqual} from 'lodash';
import moment from 'moment';
import translator from '../services/translator';
import {getKeyByValue} from './commonUtils';
import {STANDARD_REPORT_VIEW_NAMES} from '../containers/Reports/utils';
import {getFormattedDate, getPreviousBusinessDate} from './dateFormatter';
import {DATE_TIME_FORMATTERS} from '../constants/appConstants';

const {translate: t} = translator;

const isPreviousBusinessDay = (view) => {
  const {date: {fromDate, toDate, preset} = {}} = view;
  const areFromToDatesSame = (fromDate && toDate && isEqual(fromDate, toDate)) || false;
  const previousBusinessDate = getFormattedDate(
    getPreviousBusinessDate(),
    DATE_TIME_FORMATTERS.YYYY_MM_DD
  );
  return (preset === 'PRIOR_BUSINESS_DAY') || (areFromToDatesSame && (fromDate === previousBusinessDate)) || false;
};

const isCurrentBusinessDay = (view) => {
  const {date: {fromDate, toDate, preset} = {}} = view;
  const areFromToDatesSame = (fromDate && toDate && isEqual(fromDate, toDate)) || false;
  return (preset === 'TODAY') || (areFromToDatesSame && moment(fromDate).isSame(moment(), 'day')) || false;
};

const getSavedViewDetails = (allViews, viewId) => {
  if (viewId && !isEmpty(allViews)) {
    const view = allViews.find(({id}) => id === viewId); // Find active view
    if (! isEmpty(view)) {
      const {baseView, groupBy} = view;
      const savedViewPageId = getKeyByValue(STANDARD_REPORT_VIEW_NAMES, baseView);
      return {groupBy, savedViewPageId};
    }
  }
  return {};
};

const getProxyColumn = (
  paramItem, preferredAccountIdentifier, preferredFundIdentifier, columnsMetadata
) => {
  let item = {...paramItem};
  if (item.proxyColumn) {
    const accountIdentifierProxyColumn = item.proxyMapping.find(
      (mapping) => mapping.dataKey === preferredAccountIdentifier
    );
    const fundIdentifierProxyColumn = item.proxyMapping.find(
      (mapping) => mapping.dataKey === preferredFundIdentifier
    );
    const {field: preferredField} = accountIdentifierProxyColumn || fundIdentifierProxyColumn || {};
    const preferredColumn = columnsMetadata[preferredField];
    if (preferredColumn) {
      item = {...item, ...preferredColumn, colId: item.colId, headerName: item.headerName};
    }
  }
  return item;
};

const getGroupByForExport = (groupBy, columnMetadata, identifierMap, allColumnsMetadata) => {
  if (!groupBy || groupBy === 'none') return [];
  const groupByArr = groupBy.split('/');
  return groupByArr.map((group) => {
    if (group === 'accountIdentifier' || group === 'fundIdentifier') {
      const proxyColumnObj = getProxyColumn(
        allColumnsMetadata[group],
        identifierMap.accountIdentifier,
        identifierMap.fundIdentifier,
        columnMetadata
      );
      group = proxyColumnObj.key;
    }
    const columnObj = columnMetadata[group];
    const { complexKeys } = columnObj;
    if (complexKeys) {
      return complexKeys.join('-');
    }
    return group;
  });
};

const getAggColumnsForExport = (aggColumns = [], columnMetadata, identifierMap, allColumnsMetadata) => {
  if (aggColumns.length === 0) return [];

  return aggColumns.map((group) => {
    if (group === 'accountIdentifier' || group === 'fundIdentifier') {
      const proxyColumnObj = getProxyColumn(
        allColumnsMetadata[group],
        identifierMap.accountIdentifier,
        identifierMap.fundIdentifier,
        columnMetadata
      );
      group = proxyColumnObj.key;
    }
    const columnObj = columnMetadata[group];
    if (isEmpty(columnObj)) {
      return null;
    }
    const { complexKeys } = columnObj;
    if (complexKeys) {
      return complexKeys.join('-');
    }
    return group;
  }).filter(col => col);
};

const dropDownLabelTemplateTks = {
  'reps': ['tkAllReps', 'tkReps'],
  'tradeStatus': ['tkAllTradeStatus', 'tkTradeStatus'],
  'currencies': ['tkAllCurrencies', 'tkCurrencies2'],
  'fundTypes': ['tkAllFundTypes', 'tkFundTypes'],
  'fundManagers': ['tkAllInvestmentManagers', 'tkInvestmentManager'],
  'holdingStatus': ['tkAllHoldingStatus', 'tkHoldingStatus'],
  'holdingStatusForFb': ['tkAllHoldingStatus', 'tkHoldingStatus'],
  'currencyCode': ['tkAllCurrencies', 'tkCurrencies2'],
  'fundType': ['tkAllFundTypes', 'tkFundTypes'],
  'fundFamily': ['tkAllInvestmentManagers', 'tkInvestmentManager'],
  'transactionState': ['tkAllTradeStatus', 'tkTradeStatus'],
  'ZeroBalanceAccount': ['tkZeroBalanceAccounts', 'tkInclude', 'tkExclude'],
  'accountWithNoTransactions': ['tkAccountsWithNoTransactions', 'tkInclude', 'tkExclude'],
  'intradayNav': ['tkIntraDayNAV', 'tkInclude', 'tkExclude'],
  'midMonthlyDividend': ['tkMidMonthlyDividend', 'tkInclude', 'tkExclude'],
};

const getExportCoverPageCriteriaParameters = (criterias) => {
  const radioDropdowns = ['ZeroBalanceAccount', 'intradayNav', 'midMonthlyDividend', 'accountWithNoTransactions'];
  const parameters = criterias.map((criteria) => {
    const { itemId, items } = criteria;
    const selectedItem = items.filter((item) => item.selected);
    let value = '';
    if (selectedItem.length === items.length) return null;
    value = selectedItem.map(item => item.label).join(', ');

    const labelArr = dropDownLabelTemplateTks[itemId];
    let label = 'NA';
    if (! isEmpty(labelArr)) {
      label = radioDropdowns.indexOf(itemId) !== -1 ? t(labelArr[0]) : t(labelArr[1]);
    }

    return {
      label,
      value
    };
  });

  return parameters.filter(parameter => parameter);
};

const getExportCoverPageFirmParameter = (nodeMap, entity) => {
  if (!nodeMap || !entity) return {};

  const getValue = (entityMap, seletedEntities, label) => {
    const value = Object.keys(seletedEntities).map(id => {
      const obj = entityMap[id];
      const {label} = obj;
      return `${label}`;
    }).join(', ');
    return {
      label,
      value
    };
  };

  let parameter = {};
  const {selected, firmNodeMap, shareclassNodeMap, taAccountNodeMap} = nodeMap;
  switch(entity) {
    case 'firmBranch': {
      const {firmBranchSelected: {firmNodeMapSelected}} = selected;
      parameter = getValue(firmNodeMap, firmNodeMapSelected, t('tkSelectedFirm(s)'));
      break;
    }
    case 'account': {
      const {taAccountsSelected} = selected;
      parameter = getValue(taAccountNodeMap, taAccountsSelected, t('tkSelectedAccount(s)'));
      break;
    }
    case 'shareclass': {
      const {shareclassSelected} = selected;
      parameter = getValue(shareclassNodeMap, shareclassSelected, t('tkSelectedFund(s)'));
      break;
    }
    default:
      break;
  }
  return parameter;
};

const getExportCoverPageDateParameter = ({fromDate, toDate}) => {
  return {
    label: t('tkDateRange'),
    value: `${fromDate} - ${toDate}`
  };
};

const getExportCoverPageGroupByParameter = (groupByArr, reportGroupBy) => {
  if (isEmpty(groupByArr) || isEmpty(reportGroupBy)) return {};

  const groupByObj = groupByArr.find(groupBy => groupBy.value === reportGroupBy);
  return {
    label: t('tkGroupBy'),
    value: isEmpty(groupByObj) ? '' : groupByObj.label
  };
};

const createSavedEntitlementMap = (filters) => {
  const filterKeysArr = [
    'accountId',
    'shareclassId',
    'scid',
    'acctid',
    'firmID',
    'branchID',
  ];
  const obj = {};
  if (!isEmpty(filters)) {
    filters.forEach((filter) => {
      const {fieldName, filterValues} = filter;
      const matchIndex = filterKeysArr.indexOf(fieldName);
      if (matchIndex !== -1) {
        const filterKey = filterKeysArr[matchIndex];
        if (!obj[filterKey]) {
          obj[filterKey] = new Set();
        }
        filterValues.forEach(value => {
          obj[filterKey].add(value);
        });
      }
    });
  }
  return obj;
};

const createSavedCriteriaMap = (additionalCriteria) => {
  const filterKeysArr = [
    'firmIds',
    'branchIds',
    'taAccounts',
    'shareclassIds'
  ];
  const obj = {};
  if (!isEmpty(additionalCriteria)) {
    additionalCriteria.forEach((criteria) => {
      const {key, values} = criteria;
      const matchIndex = filterKeysArr.indexOf(key);
      if (matchIndex !== -1) {
        const filterKey = filterKeysArr[matchIndex];
        if (!obj[filterKey]) {
          obj[filterKey] = new Set();
        }
        values.forEach(value => {
          obj[filterKey].add(value);
        });
      }
    });
  }
  return obj;
};

const getAccountRelations = () => {
  return {
    accountIds: {}
  };
};


const filterNodeMap = (
    entitlementsData,
    {shareclassNodeMap, taAccountNodeMap, shareclassNodeMapWithoutAcct, shareclassValuesObjWithoutAcct = {}, shareclassValuesWithoutAcct = []},
    additionalSelectedFilter = {},
    showUnfundedShareclasses = false
  ) => {
  const {taAccounts, shareclassIds} = additionalSelectedFilter;

  const firmNodeMapSelected = {};
  const branchNodeMapSelected = {};
  const shareclassSelected = {};
  const taAccountsSelected = {};
  const shareclassSelectedWihtoutAcct = {};

  const accountMap = {};
  const taAccountMap = {};
  const shareclassMap = {};

  const taAccountValuesObj = {};
  const shareclassValuesObj = {};

  entitlementsData.forEach((data) => {
    const {
      firmId,
      branchId,
      taAccountId,
      shareclassId,
      accountId
    } = data;

    firmNodeMapSelected[firmId] = 1;
    branchNodeMapSelected[branchId] = 1;

    if (accountMap[accountId]) {
      console.log(`Duplicate Account Id = ${accountId} for firm Id = ${accountMap[accountId].firmId} branch Id = ${accountMap[accountId].branchId}`);
    }
    accountMap[accountId] = { firmId, branchId, shareclassId, accountId, taAccountId };

    if (taAccounts === undefined || (taAccounts && taAccounts.has(taAccountId))) {
      taAccountsSelected[taAccountId] = 1;

      if (! shareclassMap[shareclassId]) {
        shareclassMap[shareclassId] = getAccountRelations();
      }
      shareclassMap[shareclassId].accountIds[accountId] = 1;
      const shareclassNode = shareclassNodeMap[shareclassId];
      if (shareclassNode) {
        const shareclassOrder = shareclassNode.order;
        if (! shareclassValuesObj[shareclassOrder]) {
          const {label, value, order} = shareclassNode;
          shareclassValuesObj[shareclassOrder] = {label, value, order};
        }
      }
    }
    if (shareclassIds === undefined || (shareclassIds && shareclassIds.has(shareclassId))) {
      shareclassSelected[shareclassId] = 1;
    }

    if (! taAccountMap[taAccountId]) {
      taAccountMap[taAccountId] = getAccountRelations();
    }
    taAccountMap[taAccountId].accountIds[accountId] = 1;

    const taAccountNode = taAccountNodeMap[taAccountId];
    if (taAccountNode) {
      const taAccountOrder = taAccountNode.order;
      if (! taAccountValuesObj[taAccountOrder]) {
        const {label, value, order} = taAccountNode;
        taAccountValuesObj[taAccountOrder] = {label, value, order};
      }
    }

    if (showUnfundedShareclasses) {
      if (shareclassNodeMapWithoutAcct[shareclassId]) {
        shareclassSelectedWihtoutAcct[shareclassId] = 1;
      }
    }
  });

  return {
    selected: {
      firmBranchSelected: {
        firmNodeMapSelected,
        branchNodeMapSelected
      },
      shareclassSelected,
      taAccountsSelected,
      shareclassSelectedWihtoutAcct
    },
    accountMap,
    taAccountMap,
    shareclassMap,

    taAccountValuesObj,
    taAccountValues: Object.values(taAccountValuesObj),
    shareclassValuesObj,
    shareclassValues: Object.values(shareclassValuesObj),

    shareclassValuesObjWithoutAcct,
    shareclassValuesWithoutAcct
  };
};


const getDataFilteredByFirmBranch = (entitlementsData, additionalSelectedFilter) => {
  if (isEmpty(additionalSelectedFilter)) return entitlementsData;
  return entitlementsData.filter((data) => {
    const {firmId, branchId} = data;
    if (additionalSelectedFilter.firmIds || additionalSelectedFilter.branchIds) {
      if(!additionalSelectedFilter.firmIds.has(firmId) && !additionalSelectedFilter.branchIds.has(branchId))
      return false;
    }
    return true;
  });
};

const filterContainsFirmBranch = (columns) => {
  if (! columns) return false;

  return columns.some(({key}) => key === 'firmBranch');
};

const filterContainsAccount = (columns) => {
  if (! columns) return false;

  return columns.some(({key}) => key === 'account');
};

const filterContainsShareclass = (columns) => {
  if (! columns) return false;

  return columns.some(({key}) => key === 'shareclass');
}

const addFirmBranchIdsToAdditionalCriteria = (selectedFilterData = {}, {firmBranchValuesObj, firmNodeMap}) => {
  const { firmIds = [], branchIds = []} = selectedFilterData;
  const branchIdArr = branchIds.map(branchId => (branchId && branchId.split('-')[0])).filter(branchId => branchId);
  const branchIdsSet = new Set(branchIdArr);
  const includeFirms = [];
  let includeBranch = [];

  if (! isEmpty(firmBranchValuesObj) && ! isEmpty(firmNodeMap)) {
    firmIds.forEach(firmId => {
      const firmNode = firmNodeMap[firmId];
      if (! isEmpty(firmNode)) {
        const {order: firmOrder} = firmNode;
        const firmBranchObj = firmBranchValuesObj[firmOrder];
        if (! isEmpty(firmBranchObj)) {
          const {children: branches} = firmBranchObj;
          const branchIdForFirm = [];
          let isBranchAbsent = false;
          branches.forEach(({value: branchId}) => {
            const formattedBranchId = branchId.split('-')[0];
            if (branchIdsSet.has(formattedBranchId)) {
              branchIdForFirm.push(branchId);
            } else {
              isBranchAbsent = true;
            }
          });
          if (isBranchAbsent) {
            includeBranch = [...includeBranch, ...branchIdForFirm];
          } else {
            includeFirms.push(firmId);
          }
        }
      }
    });
  }

  if (includeFirms.length === 0 && includeBranch.length === 0) return [];
  return [{key: 'firmIds', values: includeFirms}, {key: 'branchIds', values: includeBranch}];
};

const addTaAccountToAdditionalCriteria = ({taAccountIds}) => {
  if (!taAccountIds || taAccountIds.length === 0) return [];

  return [{key: 'taAccounts', values: taAccountIds}];
};

const addShareclassToAdditionalCriteria = ({shareclassIds}) => {
  if (!shareclassIds || shareclassIds.length === 0) return [];

  return [{key: "shareclassIds", values: shareclassIds}];
}

const getUpdatedDate = (isReportHasVariableColumns, date) => {
  if (isReportHasVariableColumns) {
    let preset;
    if (isCurrentBusinessDay({date})) {
      preset = 'TODAY';
    } else if (isPreviousBusinessDay({date})) {
      preset = 'PRIOR_BUSINESS_DAY';
    }
    if (preset) {
      return {
        date: {
          fromDate: '',
          toDate: '',
          preset
        }
      };
    }
  }
  return {};
};

export {
  isCurrentBusinessDay,
  getSavedViewDetails,
  getProxyColumn,
  getGroupByForExport,
  getAggColumnsForExport,
  dropDownLabelTemplateTks,
  getExportCoverPageCriteriaParameters,
  getExportCoverPageFirmParameter,
  getExportCoverPageDateParameter,
  getExportCoverPageGroupByParameter,
  filterNodeMap,
  getAccountRelations,
  createSavedEntitlementMap,
  createSavedCriteriaMap,
  getUpdatedDate,
  addFirmBranchIdsToAdditionalCriteria,
  getDataFilteredByFirmBranch,
  filterContainsFirmBranch,
  filterContainsAccount,
  filterContainsShareclass,
  addTaAccountToAdditionalCriteria,
  addShareclassToAdditionalCriteria
};
