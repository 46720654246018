import React, {useState, useEffect, useReducer, useRef} from 'react';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Picker from './picker';
import Button from '../Button';
import Repeater from '../Repeater';
import BodyEnd from '../BodyEnd';
import CustomInput from './customInput';
import SelectButton from './button';
import dropdownReducer from './reducer';
import {useDocumentClick, useWindowScroll} from '../../../utils/customHooks';
import {getPosition} from '../../../utils/dom';
import translator from '../../../services/translator';
import {Conditional} from '../Conditional';
import {RANGE_BUTTONS} from '../../../constants/appConstants';
import {getJSDateFromString, getYesterdaysDate} from '../../../utils/dateFormatter';
import './index.scss';

const {translate: t} = translator;

const KEYS = {
  DOWN : 40
};

const today = moment().toDate();
const oneWeek = moment().subtract(7, 'days').toDate();

const buttonListDefault =  [
  {label: t('tkToday'), value: RANGE_BUTTONS.TODAY, date: {start: today, end: today}, isActive: true, hidden: false, disabled: false, id:  'dp-btn-1'},
  {label: t('tk1W'),  value: RANGE_BUTTONS.ONE_WEEK, date: {start: oneWeek, end: today}, isActive: false, hidden: false, disabled: false, id:  'dp-btn-2'},
];

const DatePicker = (props) => {
  const {
    minDate, maxDate, highlightDates, disabled, useWeekdaysShort, excludeDates, dateFormat,
    showDisabledMonthNavigation, filterDate, openTop, fixedMenu, id, placeholder,
    prefix, selectsRange, buttons, footerCTA, footerCTAText, closeOnSelection, onSelect, onApply,
    onSelectionComplete, onChange, disabledKeyboardNavigation, labelTemplate, type, presetRange,
    hasWarning, hasError, testId, disableWeekends, labelDateFormat, dropdownMode, showMonthDropdown,
    showYearDropdown, isReportHasVariableColumns
  } = props;
  const datePickerRef = useRef();
  const menuRef = useRef();
  const list =  buttons || buttonListDefault;
  const [isPickerDirty, setIsPickerDirty] = useState(false);
  const [label, setLabel] = useState('');
  const position = fixedMenu && getPosition(datePickerRef.current);
  const [offset, setOffset] = useState(position);
  const [eventCounter, setEventCounter] = useState(0);
  const [state, dispatch]  = useReducer(dropdownReducer, {
    startDate: props.startDate,
    endDate: props.endDate,
    preset: presetRange,
    buttonsList: list,
    selectedDate: props.selectedDate,
    expanded: false,
    items: [],
    lastSelectedButton : {},
    id
  });
  const {expanded, startDate, endDate, selectedDate, maxDate:newMaxDate, buttonsList} = state;
  const elementHeight = datePickerRef.current && datePickerRef.current.offsetHeight || 0;

  /*
* dispatch action to expand the dropdown
* sets state expanded=true
* */
  const openMenu = () => {
    setOffset(getPosition(datePickerRef.current));
    dispatch({ type: 'EXPAND' });
    // TODO: ELSOL-61952 - remove if-block when CAB and CFB allow use of today's date
    if (isReportHasVariableColumns) {
      dispatch({
        type: 'UPDATE_MAX_DATE',
        maxDate: getYesterdaysDate(),
      });
    }
  };

  /*
  * dispatch action to close the dropdown
  * sets state expanded=false
  * */
  const closeMenu = () => {
    isPickerDirty && setIsPickerDirty(false);
    dispatch({type: 'COLLAPSE'});
  };

  const updateList = (selectedButtonId) => {
    return list.map((button) => {
      return  {...button, isActive: button.id === selectedButtonId};
    });
  };

  const changeButtonSelection = (selectedButtonId) => {
    const buttonsList = updateList(selectedButtonId);
    dispatch({'type': 'UPDATE_BUTTONS_LIST', buttonsList});
  };

  /*
  * open and close dropdown list
  * */
  const toggleMenu = () => {
    return expanded ?  closeMenu() : openMenu();
  };

  /*
* returns active button props
* */
  const getActiveButton = (startDate, endDate, presetRange) => {
    const updatedButtonList = !isEmpty(buttonsList) ? buttonsList : list;
    return updatedButtonList.find((button) =>  {
      const {date, value} = button;
      if (presetRange) {
        return (value === presetRange);
      }
      if (typeof date === 'object') {
        const {start, end} = date;
        const isSameStartDate = startDate && moment(start).isSame(startDate, 'day');
        const isSameEndDate = startDate && moment(end).isSame(endDate, 'day');
        return button.isActive || (isSameStartDate && isSameEndDate);
      }
      return button.isActive;
    }) || {};
  };

  /*
   * 1. format the display label.
   * 2. you can pass your own "labelTemplate" callback function
   * for custom formatting
   * */
  const formatLabel = (dates) => {
    const isSame = moment(startDate).isSame(endDate);
    if (typeof labelTemplate === 'function') {
      return labelTemplate(dates);
    } else if (((selectedDate || startDate) && !endDate) || isSame) {
      const start = moment(selectedDate || startDate).format(labelDateFormat);
      return `${prefix} ${start}`;
    } else if (startDate && endDate) {
      const start = moment(startDate).format(labelDateFormat);
      const end =  moment(endDate).format(labelDateFormat);
      return `${prefix} ${start} - ${end}` ;
    }
  };

  const setText = () => {
    const dates = {start: startDate || selectedDate, end:  endDate, selectedDate};
    const label = formatLabel(dates);
    setLabel(label);
  };

  const handleRangeSelect = () => {
    const dates = {
      start: startDate || selectedDate,
      end:  endDate, selectedDate,
      value: state.value
    };
    const isSame = moment(startDate).isSame(endDate);
    if (isSame || (startDate && endDate) || (!expanded)) {
      (typeof onSelectionComplete === 'function') && onSelectionComplete(dates);
    }
  };

  const handleKeyDown = () => {};

  const disableWeekendsDates = (date) => {
    const day = date && moment(date).day() || 0;
    return (disableWeekends ? day !== 0 && day !== 6 : day >= 0 && day <= 6);
  };

  /**
   * following useEffect function is used as callback onChange of lastAppliedOptionsState
   ** */
  useEffect(() => {
    !isPickerDirty && setText();
  }, [selectedDate, startDate, endDate, isPickerDirty]);

  useEffect(() => {
   if(eventCounter) {
     handleRangeSelect();
   }
  }, [eventCounter]);

  /**
   * following useEffect function is used as callback onChange of lastAppliedOptionsState
   ** */
  useEffect(() => {
    const {selectedDate: date} = props;
   // TODO - to check !selectedDate condition on following commented code.
    // date && !selectedDate && dispatch({type: 'UPDATE_SELECTED_DATE', selectedDate: date});
    date && dispatch({type: 'UPDATE_SELECTED_DATE', selectedDate: date});
    if (typeof onChange === 'function') {
      /*
         On specific situations, For some reason this function call without timeout doesn't let the above
         "dispatch -> reducer" update to complete successfully, eventually goes into loop and ends up with "Maximum update depth
         exceeded error" and we get the BLANK screen. Adding setTimeout around this prevents this from happening, as this gets
         executed after "dispatch -> reducer" state update complete (due to Javascript Call Stack priority).
      */
      setTimeout(() => {
        onChange(selectedDate, props);
      });
    }
  }, [props.selectedDate]);

  const setPresetDate = (removeSelectedDate = false) => {
    const {startDate, endDate, presetRange} = props;
    const {value, id, date} = getActiveButton(startDate, endDate, presetRange);
    const isPresetDateRange = (value && date);
    const isCustomDateRange = (startDate && endDate);
    const updateButtonsListAndRange = isPresetDateRange || isCustomDateRange;
    if (updateButtonsListAndRange) {
      const {start, end} = date || {
        start: getJSDateFromString(startDate),
        end: getJSDateFromString(endDate)
      };
      const list = updateList(id);
      if (!isEmpty(list)) {
        dispatch({
          type: 'UPDATE_BUTTONS_LIST_AND_RANGE',
          startDate: start,
          endDate: end,
          selectedButtonId: id,
          buttonsList: list,
          value: presetRange
        });
      }

      if (removeSelectedDate) {
        dispatch({
          type: 'UPDATE_SELECTED_DATE',
          selectedDate: undefined,
          selectedButtonId: undefined
        });
      }
    }
  };

  useEffect(() => {
    setPresetDate();
  }, [presetRange, buttons]);

  useWindowScroll(() => {
    if(expanded) {
      const pos = getPosition(datePickerRef.current);
      setOffset(pos);
    }
  }, [expanded]);

  const setDates = (dates, removeSelectedDate = false) => {
    const [start, end] = dates;
    // const isSame = moment(start).isSame(end);
    const {id} = getActiveButton(start, end, presetRange);
    const list = updateList(id);
    start && dispatch({
      type: 'UPDATE_BUTTONS_LIST_AND_RANGE',
      startDate: start,
      endDate: end,
      selectedButtonId: id,
      buttonsList: list
    });

    (!end || removeSelectedDate) && dispatch({
      type: 'UPDATE_SELECTED_DATE',
      selectedDate: removeSelectedDate ? (end || undefined) : start,
      selectedButtonId: undefined
    });
    changeButtonSelection('');
  };

  /*
   * handle the key board events
   * allow to navigate dropdown items using keyboard arrow keys
   * */
  const onKeyDown =(event) => {
    const keyCode = event.keyCode && event.keyCode || event.which;
    const {DOWN} = KEYS;
    if (keyCode === DOWN) {
      openMenu();
    }

    // prevent default is required for stoping
    // window.event.preventDefault();
    if(event.preventDefault) event.preventDefault(); else event.returnValue = false;
    event.stopPropagation();
    return false;
  };

  /** ***************************************************
          Called when OK button clicked on CTA
   ****************************************************** */
  const handleDatesApply = (event) => {
    event.stopPropagation();

    // If the user has just selected Start Date, then set End Date as same date
    if (!endDate) {
      dispatch({
        type: 'UPDATE_START_AND_END_DATES',
        startDate,
        endDate: startDate
      });
    }

    if (typeof onApply === 'function') {
      const dates = {start: startDate || selectedDate, end: endDate, selectedDate};
      onApply(dates);
    }
    setText();
    closeMenu();
    setEventCounter(eventCounter + 1);
  };

  /** ***************************************************
          Called when Cancel button clicked on CTA
   ****************************************************** */
  const handleCancel = (event) => {
    event && event.stopPropagation();
    if (props.startDate || props.endDate) {
      const dates = [props.startDate, props.endDate];
      setDates(dates, true);
    } else {
      setPresetDate(true);
    }
    setTimeout(() => {
      closeMenu();
    }, 0);
  };

  /** ***************************************************
         Called when any of the Preset is selected
   ****************************************************** */
  const handlePresetsChange = (e, value, data) => {
    setIsPickerDirty(false);
    const {start, end} = data.date;
    dispatch({type: 'UPDATE_SELECTED_DATE', selectedDate: end, selectedButtonId: data.id, value});
    dispatch({type: 'UPDATE_DATE_RANGE', startDate: start, endDate: end, selectedButtonId: data.id, value});
    changeButtonSelection(data.id);
    closeOnSelection && closeMenu();
    setEventCounter(eventCounter + 1);
  };

  /** ***************************************************
          Called when date is changed in Picker
   ****************************************************** */
  const handleDatesChange = (dates) => {
    setIsPickerDirty(true);
    if (Array.isArray(dates)) {
      setDates(dates);
    } else {
      dispatch({
        type: 'UPDATE_SELECTED_DATE',
        selectedDate: dates,
        selectedButtonId: undefined
      });
      changeButtonSelection('');
      closeMenu();
      setEventCounter(eventCounter + 1);
    }
  };

  const handleSelect = (date) => {
    typeof onSelect === 'function' && onSelect(date);
  };

  useDocumentClick((event) => {
    if (expanded) {
      const clickedOnComponent = datePickerRef && datePickerRef.current.contains(event.target)
        || menuRef && menuRef.current && menuRef.current.contains(event.target);
      !clickedOnComponent && handleCancel();
    }
  }, id);

  const formatWeekDay = (nameOfDay) => nameOfDay.substr(0, 3);

  const height = menuRef.current && menuRef.current.offsetHeight || 0;
  const top = (offset.top || position.top) - ((openTop ? height : 0) + (openTop ? 0 : -elementHeight));
  const style =  {left: (offset.left || position.left),  top, display: 'flex'};
  const selected = !disabled && (selectedDate || startDate);

  const datePickerProps = {
    selected, selectsRange, dateFormat, startDate, endDate, handleChange: handleDatesChange,
    handleSelect, useWeekdaysShort, minDate, maxDate: (newMaxDate || maxDate),
    showDisabledMonthNavigation, disabledKeyboardNavigation, excludeDates, highlightDates,
    disableWeekends, dropdownMode, filterDate: filterDate || disableWeekendsDates, disabled,
    showMonthDropdown, showYearDropdown, formatWeekDay, focusSelectedMonth: true
  };
  const pickerProps = {...datePickerProps};

  return (
    <Conditional condition={type==='dropdown'}>
      <div className={cn('new', {'top': openTop})}>
        <div className={cn('date-picker', {'show': expanded})} aria-label="Date Picker" ref={datePickerRef} onKeyDown={onKeyDown} tabIndex={0}>
          <button disabled={disabled} onClick={toggleMenu} className={cn('dropdown__label', {'dropdown__label--open': expanded})} id={id}>
            <span className="date-picker__labelText" >{label || placeholder}</span>
            <span className={cn('date-picker__labelIcon', {'dropdown__chevron--open': expanded, 'dropdown__chevron--closed': !expanded} )} >&nbsp;</span>
          </button>
          <BodyEnd cssClass={cn('new', {'top': openTop})}>
            <div className="date-picker">
              <div
                ref={menuRef}
                style={style}
                role="menu"
                className={cn('date-picker__menu', {'date-picker__menu--show': expanded, 'date-picker__menu--hide': !expanded})}
                aria-labelledby={id}
                tabIndex="0"
              >
                <div className="date-picker__menu-picker-section">
                  <div className="date-picker__menu-left">
                    <div className="date-picker__menu-top">
                      <Repeater items={buttonsList} props={{handlePresetsChange}}>
                        <SelectButton />
                      </Repeater>
                    </div>
                  </div>
                  <div className="date-picker__menu-right">
                    <div className="date-picker__menu-center">
                      <Picker
                        inline
                        {...pickerProps} />
                    </div>
                  </div>
                </div>
                <Conditional condition={footerCTA}>
                  <div className="date-picker__menu-action-bar-section">
                    <div className="date-picker__seperator" />
                    <div className='date-picker__menu-bottom' >
                      <Button
                        testId='date-picker-cta_cancel'
                        clickHandler={handleCancel}
                        label={t('tkCancel')}
                        customClass="button-secondary-small"
                        extraClass='date-picker__btn date-picker__btn--cancel'
                      />
                      <Button
                        testId='date-picker-cta_apply'
                        clickHandler={handleDatesApply}
                        label={footerCTAText}
                        isDisabled={!isPickerDirty}
                        customClass="button-primary-small"
                        extraClass='date-picker__btn date-picker__btn--apply'
                      />
                    </div>
                  </div>
                </Conditional>
              </div>
            </div>
          </BodyEnd>
        </div>
      </div>
      <div data-testid={testId} ref={datePickerRef} className={cn('date-picker', 'date-picker__container', {'no-show' : !expanded, 'warning' : hasWarning, error: hasError, disabled})}>
        <Picker
          inline={false}
          {...pickerProps}
          customInput={
            <CustomInput
              handleOpen={openMenu}
              handleKeyDown={handleKeyDown}
              isDisabled={disabled}
              selectedDate={selectedDate && !disabled ? selectedDate :  undefined}
              open={expanded} />
          }
        />
      </div>
    </Conditional>
  );

};

DatePicker.defaultProps = {
  type: 'popover',
  id : 'date-picker-default-id',
  labelDateFormat: 'DD.MMM.YYYY',
  dateFormat: 'dd.MMM.yyyy',
  useWeekdaysShort : false,
  disableWeekends: false,
  showDisabledMonthNavigation: true,
  disabledKeyboardNavigation: true,
  minDate: undefined, // moment().subtract(2, 'years').toDate(),
  maxDate: undefined, // moment().add(7, 'days').toDate(),
  disabled: false,
  highlightDates: [],
  excludeDates: [],
  selectsRange: true,
  footerCTA: true,
  closeOnSelection: true,
  footerCTAText: t('tkApplyChanges'),
  openTop: false,
  fixedMenu: true,
  onSelect: null,
  onChange: null,
  onSelectionComplete ( ) {

  },
  onApply:  null,
  filterDate: null,
  prefix : 'DATE:',
  placeholder : 'SELECT DATE',
  labelTemplate : null,
  showMonthDropdown: false,
  showYearDropdown: false,
  width: '282px'
};

DatePicker.propTypes = {
  testId: PropTypes.string,
  type: PropTypes.string,
  id : PropTypes.string,
  buttons: PropTypes.arrayOf(Object),
  labelDateFormat: PropTypes.string,
  dateFormat: PropTypes.string,
  useWeekdaysShort : PropTypes.bool,
  disableWeekends: PropTypes.bool,
  showDisabledMonthNavigation: PropTypes.bool,
  disabledKeyboardNavigation: PropTypes.bool,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  selectedDate: PropTypes.instanceOf(Date),
  footerCTA: PropTypes.bool,
  closeOnSelection: PropTypes.bool,
  footerCTAText: PropTypes.string,
  disabled:  PropTypes.bool,
  highlightDates:  PropTypes.arrayOf(Date),
  excludeDates:  PropTypes.arrayOf(Date),
  selectsRange:  PropTypes.bool,
  openTop: PropTypes.bool,
  fixedMenu: PropTypes.bool,
  onSelect: PropTypes.func,
  onApply: PropTypes.func,
  onSelectionComplete: PropTypes.func,
  onChange: PropTypes.func,
  filterDate: PropTypes.func,
  placeholder: PropTypes.string,
  prefix: PropTypes.string,
  labelTemplate: PropTypes.func,
  presetRange: PropTypes.string,
  width: PropTypes.string,
  hasWarning: PropTypes.bool,
  hasError: PropTypes.bool,
  showMonthDropdown: PropTypes.bool,
  showYearDropdown: PropTypes.bool,
  dropdownMode: PropTypes.string,
  isReportHasVariableColumns: PropTypes.bool
};

export default DatePicker;


/* TODO: To Remove
 // This is ad Example how to configure the Date Picker
const today = moment().toDate();
const yesterday = moment().subtract(1, 'days').toDate();
const oneWeek = moment().subtract(7, 'days').toDate();
const mtd =  moment().startOf('month').toDate();
const threeMonths =  moment().subtract(3, 'months').toDate();

const buttonListDefault =  [
  {label: t('tkToday'), value: {start: today, end: today}, isActive: false, hidden: false, disabled: false, id:  'dp-btn-1'},
  {label: t('tkT-1'), value: {start: yesterday, end: yesterday}, isActive: false, hidden: false, disabled: false, id:  'dp-btn-2'},
  {label: t('tk1W'), value: {start: oneWeek, end: today}, isActive: true, hidden: false, disabled: false, id:  'dp-btn-3'},
  {label: t('tkMTD'), value: {start: mtd, end: today}, isActive: false, hidden: false, disabled: false, id:  'dp-btn-4'},
  {label: t('tk3M'), value: {start: threeMonths, end: today}, isActive: false, hidden: false, disabled: false, id:  'dp-btn-5'},
];
*/
