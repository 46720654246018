import isEmpty from 'lodash/isEmpty';

export const additionalFiltersServiceFieldMapper = {
  'reps': 'reps',
  'currencies': 'currencies',
  'tradeStatus': 'tradeStatus',
  'fundTypes': 'fundTypes',
  'fundManagers': 'fundManagers',
  'holdingStatus': 'holdingStatus',
  'holdingStatusForFb': 'holdingStatus',
  'intradayNav': 'intradayNav',
  'midMonthlyDividend': 'midMonthlyDividend',
  'ZeroBalanceAccount': 'ZeroBalanceAccount',
  'transactionState': 'transactionState',
  'accountWithNoTransactions': 'accountWithNoTransactions'
};

export const entityFilters = [
  'reps',
  'currencies',
  'fundTypes',
  'fundManagers'
];

export const getReportPayLoad = (
  baseReport = '', fields = [], filters = [],
  toggle = ''
) => ({
  baseReport,
  fields,
  filters,
  toggle
});

export const getStitchedReportPayLoad = (
  baseReport = '', fields = [], filters = []
) => ({
  baseReport,
  fields,
  filters
});

export const getFieldFilter = (fieldName, filterType, filterValues) => ({
  fieldName,
  filterType,
  filterValues
});

export const getAdditionalFieldFilter = (fieldName, value) => {
  const [fieldValue] = value;
  if (fieldValue === 'EXCLUDE') {
    return {
      filterType: 'ENABLE_TOGGLE',
      filterValues: [`${fieldValue.toLowerCase()}${fieldName}`]
    };
  }
  return null;
};

export const getAdditionalFilters = (filters, isSkipEntityFilters = true) => {
  if (!isEmpty(filters)) {
    return Object.entries(filters)
      .filter(([key, ]) => {
        if (isSkipEntityFilters) {
          return !entityFilters.includes(key);
        }
        return true;
      })
      .map(([key, value]) => {
        const fieldName = additionalFiltersServiceFieldMapper[key];
        if (fieldName) {
          switch (fieldName) {
            case 'ZeroBalanceAccount': {
              // TODO : This will be the case for other filters as well,
              //  for now only implemented for ZeroBalanceAccount
              return getAdditionalFieldFilter(fieldName, value);
            }
            default:
              return getFieldFilter(fieldName, 'IN', value);
          }
        }
        return null;
      })
      .filter(val => val);
  }
  return [];
};

export const getAccountFilter = (filterValues, accountIdKey = 'accountId') => {
  return getFieldFilter(accountIdKey, 'IN', filterValues);
};

export const getTradeDateFilter = (filterValues, field = 'tradeDate') => {
  return getFieldFilter(field, 'BETWEEN', filterValues);
};

const getStitchKey = (stitchKeyMapper, field) => {
  if (!stitchKeyMapper) {
    return field;
  }
  return stitchKeyMapper[field] || field;
};

const objectFlip = (obj) => {
  if (!obj) return undefined;
  return Object.keys(obj).reduce((ret, key) => {
    ret[obj[key]] = key;
    return ret;
  }, {});
};

export const getFields = (columnMapper, stitchKeyMapper) => {
  const fields = [];
  Object.keys(columnMapper)
    .forEach(column => {
      const columnKey = getStitchKey(stitchKeyMapper, column);
      if (columnMapper[column].complexKeys) {
        columnMapper[column].complexKeys.forEach((key) => {
          const complexColumnKey = getStitchKey(stitchKeyMapper, key);
          fields.push({
            fieldName: complexColumnKey
          });
        });
      } else {
        fields.push({
          fieldName: columnKey
        });
      }
    });
  return fields;
};

const isGroupByRow = (row) => !!row.grpId;
const groupbyCheck = (row) => (row.grpId && row.type === 'header') || row.type === 'spacer';
const instructionCheck = (row) => !!(row.displayCode && row.cashDistAmt);
const isGainLossSummaryRow = (row) => !!(row.startVal && row.gainLoss);

export const decodeHTMLInst = (inst = '') => {
  const replaceEntity = (item) => {
    const mapping = {
      '&amp;': '',
      '&lt;': '<',
      '&gt;': '>',
      'nbsp;': ' '
    };
    return mapping[item];
  };
  return inst.replace(/&amp;|&lt;|&gt;|nbsp;/g, replaceEntity);
};

const fieldToRemoveCommas = ['tCcyAmt', 'totalFeeAmount'];

const isFieldInResponse = (response) => {
  return fieldToRemoveCommas.every(item => response.hasOwnProperty(item));
};

const getFormattedResponseFromStitchData = (
  stitchReportResponse, columnMapper, stitchKeyMapper
) => {
  const data = [];
  if (!stitchReportResponse) return data;

  // const {rows = []} = stitchReportResponse;
  const reverseStitchKeyMapper = objectFlip(stitchKeyMapper);
  stitchReportResponse.forEach((row) => {
    if (isFieldInResponse(row)) {
      fieldToRemoveCommas.forEach(
        (item) => (row[item] = row[item].replace(/,/g, ''))
      );
    }
    const obj = {};
    if (!isGroupByRow(row)) {
      const keys = Object.keys(row);
      keys.forEach((key) => {
        const columnKey = getStitchKey(reverseStitchKeyMapper, key);
        const columnMapperColumn = columnMapper[columnKey];
        if (!columnMapperColumn) {
          console.log('Column Mapper => ', key, columnMapper);
        }
        const dataType = (columnMapperColumn && columnMapperColumn.dataType) || 'string';
        const type = (columnMapperColumn && columnMapperColumn.type) || false;
        const newKey = columnMapperColumn ? getStitchKey(reverseStitchKeyMapper, key) : columnKey;
        let value = row[key];
        const isValueTypeOfString = (typeof value === 'string');
        const isNumberDataType = (dataType === 'number');
        const isNumericColumn = (['variableNumericColumn', 'numericSortColumn', 'navColumn'].includes(type) || isNumberDataType);
        if (isValueTypeOfString && isNumericColumn && (value.indexOf(',') !== -1)) {
          value = value.replace(/,/g, '');
        }
        obj[newKey] = isNumberDataType ? (value ? Number(value) : '') : value;
      });
      data.push(obj);
    }
  });
  return data;
};

const getComplexColumnValue = (source, keys) => {
  let value = '';
  keys.forEach(key => {
    const data = source[key];
    value += `${data || ''} - `;
  });
  return value.substring(0, value.length - 2).trim();
};

const getValueOfNestedObject = (source, nestedProp) => {
  if (nestedProp.complexKeys) {
    return getComplexColumnValue(source, nestedProp.complexKeys);
  }
  const prop = typeof(nestedProp) === 'object' ? nestedProp.key : nestedProp;
  return prop
    .split('.')
    .reduce((prev, curr) => prev[curr], source);
};

export const getGainLossSummaryResponse = (response) => {
  let responseWithoutSummary = [];
  const responseWithSummary = [];
  response.forEach((row) => {
    if(!isGainLossSummaryRow(row)) {
      if(!isGroupByRow(row)) responseWithoutSummary.push(row);
    }
    else {
      responseWithoutSummary.forEach((item) => {
        const { endingValue, startVal, gainLoss, tradeValue, priceUsed, closingShares} = row;
        responseWithSummary.push({...item, summaryResponse : { 'endingBalance': endingValue,
          'beginningBalance': startVal,
          'gainLoss': gainLoss,
          'tradeBalance': tradeValue,
          'NAVTrading': priceUsed,
          'shareBalance': closingShares }
        });
      });
      responseWithoutSummary = [];
    }
    }
  );
  return responseWithSummary;
};

export const getFilteredResponse = (response) => {
  let responseWithoutInst = [];
  const responseWithInst = [];
  response.forEach((row) => {
      if (!groupbyCheck(row) && instructionCheck(row)) {
        responseWithoutInst.push(row);
      } else {
        responseWithoutInst.forEach((item) => {
          const decodeInst = decodeHTMLInst(row.displayCode);
          responseWithInst.push({...item, dcssInstruction: decodeInst});
        });
        responseWithoutInst = [];
      }
    }
  );
  return responseWithInst;
};

export const getFormattedResponse = (serviceResponse, columnMappers) => {
  const columnMapperKeys = Object.keys(columnMappers);
  return serviceResponse.map(item => {
    const formattedObj = {};
    columnMapperKeys.forEach((mapperKey) => {
      if (mapperKey !== 'undefined') {
        const value = getValueOfNestedObject(item, columnMappers[mapperKey]);
        const dataType = columnMappers[mapperKey].dataType || 'string';
        formattedObj[mapperKey] = (value === 'null' || value === undefined) ? '' : (
          dataType === 'number' ? (value ? Number(value) : '') : value
        );
        if ( dataType === 'number' && Number.isNaN(formattedObj[mapperKey])) {
          formattedObj[mapperKey] = null;
        }
      }
    });
    return formattedObj;
  });
};

export const getUpdatedServiceFieldMappers = (fieldsMapper, serviceFieldMapper) => {
  const updatedFieldMapper = {};
  Object.entries(fieldsMapper).forEach(([key, value]) => {
    const mapperField = serviceFieldMapper[key];
    if (mapperField) {
      mapperField.forEach(itemKey => {
        updatedFieldMapper[itemKey] = itemKey;
      });
    } else {
      updatedFieldMapper[key] = value;
    }
  });
  return updatedFieldMapper;
};

export const getFinalReportPayload = (payLoad, fieldsMapper, filterType, baseReport, toggle) => {
  const {date: {startDate, endDate}, additionalFilter} = payLoad;
  const filterValues = payLoad[filterType];
  const fields = getFields(fieldsMapper);
  const tradeDateFilter = getFieldFilter('tradeDate', 'BETWEEN', [startDate, endDate]);
  const entityFilter = getFieldFilter(filterType, 'IN', filterValues);
  const additionalFilters = getAdditionalFilters(additionalFilter);
  const filterFields = [
    tradeDateFilter,
    entityFilter,
    ...additionalFilters
  ];
  return getReportPayLoad(baseReport, fields, filterFields, toggle);
};

const isAllFilterCheckBoxSelected = (nodeMap, selectedNodeMap) => (
  Object.keys(nodeMap).length === Object.keys(selectedNodeMap).length
);

export const getIndividualFilter = (condition, filterKey, values) => {
  if (!condition && values) {
    return getFieldFilter(
      filterKey,
      'IN',
      values.map((item) => item.split('-')[0])
    );
  }
  return null;
};

const getAccountIds = (initialValues, taAccountIds = []) => {
  const accountIds = Object.values(initialValues.accountMap)
    .filter(({taAccountId}) => taAccountIds.includes(taAccountId))
    .map(({accountId}) => accountId);
  return accountIds;
};

export const getFilterFieldValues = (
  initial,
  firmIds,
  branchIds,
  startDate,
  endDate,
  shareclassIds = null,
  taAccountIds = null
) => {
  const tradeDateFilter = getTradeDateFilter([startDate, endDate], 'tDt');
  let isAllBranchesSelected = isAllFilterCheckBoxSelected(
    initial.branchNodeMap,
    branchIds
  );
  const isAllFundsSelected = shareclassIds &&
    isAllFilterCheckBoxSelected(initial.shareclassNodeMap, shareclassIds);
  const isAllAccountsSelected = taAccountIds &&
    isAllFilterCheckBoxSelected(initial.taAccountNodeMap, taAccountIds);

  if (isAllFundsSelected !== null && isAllAccountsSelected !== null &&
      (!isAllFundsSelected || !isAllAccountsSelected)) {
    isAllBranchesSelected = false;
  }

  const firmFilter = isAllBranchesSelected &&
    getFieldFilter('firmID', 'IN', firmIds);

  const branchFilter = getIndividualFilter(
    isAllBranchesSelected,
    'branchID',
    branchIds
  );
  const accountIds = taAccountIds && !isAllAccountsSelected && getAccountIds(initial, taAccountIds);
  const accountsFilter = !isEmpty(accountIds) && getIndividualFilter(
    false,
    'acctid',
    !isAllAccountsSelected ? accountIds : []
  );
  const fundsFilter = shareclassIds && getIndividualFilter(
    false,
    'scid',
    !isAllFundsSelected ? shareclassIds : []
  );

  return [
    tradeDateFilter,
    firmFilter,
    accountsFilter,
    fundsFilter,
    branchFilter,
  ].filter(Boolean);
};
export {getFormattedResponseFromStitchData};
