export default {
  START_POLLING_FILE_DOWNLOAD: 'START_POLLING_FILE_DOWNLOAD',
  STOP_POLLING_FILE_DOWNLOAD: 'STOP_POLLING_FILE_DOWNLOAD',
  START_FILE_DOWNLOAD: 'START_FILE_DOWNLOAD',
  QUEUE_FILE_DOWNLOAD: 'QUEUE_FILE_DOWNLOAD',
  DEQUEUE_FILE_DOWNLOAD: 'DEQUEUE_FILE_DOWNLOAD',
  ADD_FILE_DOWNLOAD_POLLING_DATA: 'ADD_FILE_DOWNLOAD_POLLING_DATA',
  OPEN_DOWNLOAD_MODAL: 'OPEN_DOWNLOAD_MODAL',
  CLOSE_DOWNLOAD_MODAL: 'CLOSE_DOWNLOAD_MODAL',
  ADD_PDF_COLUMNS_CONFIGURATION: 'ADD_PDF_COLUMNS_CONFIGURATION',
  REPORT_PDF_DOWNLOAD_STATUS: 'REPORT_PDF_DOWNLOAD_STATUS',
  QUEUE_EXEL_EXPORT_SNACKBAR: 'QUEUE_EXEL_EXPORT_SNACKBAR',
  DEQUEUE_EXEL_EXPORT_SNACKBAR: 'DEQUEUE_EXEL_EXPORT_SNACKBAR',
  DEQUEUE_ALL_FILE_DOWNLOAD: 'DEQUEUE_ALL_FILE_DOWNLOAD',
  SET_FILTER_CONFIG_DATA: 'SET_FILTER_CONFIG_DATA',
  ADD_REPORTS_CONFIG_DATA: 'ADD_REPORTS_CONFIG_DATA',
  SET_CUSTOM_REPORT_CRUD_RESPONSE: 'SET_CUSTOM_REPORT_CRUD_RESPONSE'
};
