import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import find from 'lodash/find';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import moment from 'moment/moment';
import Grid from '../../../components/core/Grid';
import withGrid  from '../../../components/hoc/withGrid';
import CustomSearchFilter from '../../../components/core/Grid/CustomSearchFilter';
import { Conditional } from '../../../components/core/Conditional';
import { ThreeSquaresLoader } from '../../../components/core/Loaders';

import {
  getGridData, gridHeader, isLoading, filterVisibility,
  currentViewSelector, columnsMetadata, preDefinedSortingState, noRowDataSelector,
  selectedGroup, viewName, includeZBPSelector, getFilters, selectCollapseState,
  columnSortingStateMappedData, groupByFieldNew, selectedRows, startExcelExport, getFilterModel,
  filterByMappedData, getGridAggregatedData, getLastEvent, snackbarList
} from '../../../selectors/pages/portfolio';

import translator  from '../../../services/translator';
import  mapDispatchToProps from './actionsHandler';
import {userSelector} from '../../../selectors/user';
import {reportDisclaimerSelector, labelSelector, selectedFirms} from '../../../selectors/app';
import { excelExportFooterConfiguration } from '../../../configs/excelExport/exportFooter';
import {COLUMN_COLID, GROUP_AGGREGATOR_SPLITTER} from '../../../constants/pageConstants';
import { gridCompositeFormatter } from '../../../utils/portfolioGridFormatter';
import { selectedRowsComparator } from '../../../helpers/gridFund';
import { getAllDisplayedColumns } from '../../../modules/Grid/helper';
const {translate: t} = translator;

const customMapStateToProps = (state) => ({
  filterVisibility: filterVisibility(state)
});

const frameworkComponents = {
  customSearchFilter: connect(customMapStateToProps)(CustomSearchFilter)
};

const exportColumnsFunc = (api) => {
  return getAllDisplayedColumns(get(api, 'excelCreator.columnModel.displayedColumns', []));
};

const processCellCallback  = ({value, node: {data}, column: {colId, colDef} = {}}) => {
  switch (colId) {
    case COLUMN_COLID.NAV_SCHEDULE :
    case COLUMN_COLID.NAV_AS_OF_TIME :
    case COLUMN_COLID.INCEPTION_DATE :
    case COLUMN_COLID.PERFORMANCE_AS_OF:
    case COLUMN_COLID.ASSETS_AS_OF:
    case COLUMN_COLID.DURATION_AS_OF:
    case COLUMN_COLID.LIQUIDITY_ASSETS_AS_OF:
      return (value && value.length) ? gridCompositeFormatter({data, colDef, value}) : value;
    case COLUMN_COLID.ADJUSTED_ONE_DAY_YIELD:
    case COLUMN_COLID.ADJUSTED_SEVEN_DAY_CURR_YIELD:
    case COLUMN_COLID.ADJUSTED_SEVEN_DAY_EFF_YIELD:
    case COLUMN_COLID.ADJUSTED_THIRTY_DAY_YIELD:
      return gridCompositeFormatter({data, colDef, value});
    default :
      return value;
  }
};


const PortfolioGrid  = (props) => {

  const getExcelExportConfig = () => {
    const {
      currentView,
      disclaimer,
      userInformation,
      filterByMappedData,
      columnSortingStateMappedData,
      selectedFirms,
      includeZeroBalancePositions,
      labels,
    } = props;
    const sheetName = labels.tkNavMenuPortfolio;
    const viewLabel = currentView && currentView.label ? currentView.label.split(':')[0].trim().replace(/ /g, '_') : '';
    const fileName = `${viewLabel}_${moment().format('DDMMMYYYY')}`.toUpperCase();
    const footer = excelExportFooterConfiguration({
      disclaimer,
      userInformation,
      filterByMappedData,
      columnSortingStateMappedData,
      selectedFirms,
      includeZeroBalancePositions,
      labels,
    });
    return { footer, fileName, sheetName, skipGroups: true, exportColumnsFunc, processCellCallback };
  };

  // removing "--" from non-aggregated columns
  const removingNonAggregatedTotal = (foundItem, columnApi) => {
    if (foundItem) {
      const foundItemKeys = Object.keys(foundItem);
      const dataKeys = [];
      const primaryColumns = columnApi.getPrimaryColumns();
      forEach(primaryColumns, column => {
        dataKeys.push(column.colId);
      });
      dataKeys.forEach(field => {
        if (!foundItemKeys.includes(field)) {
          foundItem[field] = ' ';
        }
      });
    }
  };

  const groupRowAggNodes = (nodes, aggregatedData, columnApi) => {
    /* foundItem is the row with all the total values to be found on the aggregated data */
    /* his.props.aggregatedData has all aggregated totals per group */
    let foundItem = {};
    nodes.forEach((node) => {
      /* aggregatedValueGroup label contains the name of the group for single grouping and
      /* and for double grouping, inner group contains a combination of "parent+GROUP_AGGREGATOR_SPLITTER+child
      /* first check to see if it is a leaf group or not for single grouping */
      if(node.leafGroup && node.leafGroup === true && !node.data) {
        foundItem = find(aggregatedData, (aggregatedValueGroup) => {
          const labelSplitArr= aggregatedValueGroup.label.split(GROUP_AGGREGATOR_SPLITTER);
          /* if label split array has length = 1 means that the aggregated value is for single grouping */
          if(labelSplitArr.length === 1 && node.aggData) {
            /* if the aggregatedValueGroup.label matches the label of the node then  return the aggregated data corresponding to this group */
            const labelSplitArrFirstLevel = node.aggData.label.split(GROUP_AGGREGATOR_SPLITTER);
            return labelSplitArrFirstLevel[0] === labelSplitArr[0];
          }
        });
      }
      /* Not leaf group */
      else if(node.data){
        /* checking if the the node data for the aggregated group matches any of the aggregatedData labels */
        foundItem = find(aggregatedData, (aggregatedValueGroup) => {
          const labelSplitArr = aggregatedValueGroup.label.split(GROUP_AGGREGATOR_SPLITTER);
          if(labelSplitArr.length < 2) {
            return node.data[aggregatedValueGroup.groupKey] === labelSplitArr[0];
          }
        });
        /* if  there is double grouping and a found item, then for returning the totals for an inner group the aggregatedValue object label
        /* has to match the found item label and then the data attribute for the groupKey hast to match the second par of the label */
        if(props.groupByField.includes('And') && foundItem) {
          foundItem = find(aggregatedData, (aggregatedValueGroup) => {
            const labelSplitArr = aggregatedValueGroup.label.split(GROUP_AGGREGATOR_SPLITTER);
            if (labelSplitArr.length > 1) {
              return foundItem.label === labelSplitArr[0] &&
                node.data[aggregatedValueGroup.groupKey] === labelSplitArr[1];
            }
          });
        }
      }
      removingNonAggregatedTotal(foundItem, columnApi);
    });
    return foundItem;
  };

  const config = {
    groupRowAggNodes,
    enableHandleGrouping: true,
    groupDefaultExpanded: props.collapseState === true ? 0 : -1,
    enableServerSideSorting: true,
    suppressScrollOnNewData: true,
    groupSuppressAutoColumn: true,
    context: { // custom prop
      suppressGroupCheckbox: true
    },
    sortingOrder: ['desc', 'asc'],
    noRowsOverlayComponentParams : {
      noRowMsg: props.isInvalidFilter ? t('tkCopyPort17') : noRowDataSelector
    },
    floatingFiltersHeight: 0
  };

  const [startTime, setStartTime] = useState(null);
  const onDispatchLoadStart = () => {
    setStartTime(new Date().getTime());
  };

  const onDispatchLoadEnd = () => {
    const {lastEvent, data, columnDefs, currentView, filterBy, snackbarList} = props;
    lastEvent && props.dispatchGridLoadEnd(
      lastEvent,
      data.length,
      columnDefs,
      currentView.name,
      filterBy,
      startTime,
      snackbarList
    );
  };


  const updatedProps = {
    ...props,
    frameworkComponents,
    config,
    dispatchLoadEnd: onDispatchLoadEnd,
    dispatchLoadStart: onDispatchLoadStart,
    excelExportConfig: getExcelExportConfig(),
    selectedRowsComparator,
  };

  return (
    // while columnDefs not populated, show loader component
    <Conditional condition={!props.columnDefs.length}>
      <ThreeSquaresLoader />
      {withGrid(Grid)(updatedProps)}
    </Conditional>
  );
};

const mapStateToProps = (state) => ({
  columnDefs: gridHeader(state),
  data: getGridData(state),
  aggregatedData: getGridAggregatedData(state),
  groupByField: groupByFieldNew(state),
  viewName: viewName(state),
  lastEvent: getLastEvent(state),
  collapseState: selectCollapseState(state),
  filterVisibility: filterVisibility(state),
  filterBy: getFilters(state),
  userInformation: userSelector(state),
  selectedGroup: selectedGroup(state),
  labels: labelSelector(state),
  currentView: currentViewSelector(state),
  includeZeroBalancePositions: includeZBPSelector(state),
  selectedRows: selectedRows(state),
  noRowDataSelector: noRowDataSelector(state),
  loading: isLoading(state),
  filterModel : getFilterModel(state),
  columnsMetadata: columnsMetadata(state),
  preDefinedSortingState : preDefinedSortingState(state),
  startExcelExport: startExcelExport(state),
  filterByMappedData : filterByMappedData(state),
  columnSortingStateMappedData : columnSortingStateMappedData(state),
  disclaimer: reportDisclaimerSelector(state),
  selectedFirms: selectedFirms(state),
  enableAddTradeToBlotter: true,
  snackbarList: snackbarList(state)
});

PortfolioGrid.propTypes = {
  columnDefs: PropTypes.array,
  data: PropTypes.array,
  groupByField: PropTypes.string,
  lastEvent: PropTypes.object,
  collapseState: PropTypes.bool,
  filterBy: PropTypes.array,
  userInformation: PropTypes.object,
  labels: PropTypes.object,
  currentView: PropTypes.object,
  includeZeroBalancePositions: PropTypes.bool,
  filterByMappedData: PropTypes.object,
  columnSortingStateMappedData: PropTypes.object,
  disclaimer: PropTypes.string,
  selectedFirms: PropTypes.array,
  snackbarList: PropTypes.array,
  isInvalidFilter: PropTypes.bool,
  dispatchGridLoadEnd: PropTypes.func,
};

export default  connect(mapStateToProps, mapDispatchToProps)(PortfolioGrid);
